import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';

import { TradingType } from 'src/shared/types/global-types';
import { store } from 'src/app/store/store';
import {
  EInputEventType, EOutputEventType, ISubscribeEvent, IUnsubscribeEvent,
} from 'src/shared/types/ws-interfaces';
import { devConsoleLog } from 'src/shared/libs/helpers/helper.lib';
import { socket } from 'src/shared/api/websocket.instance';
import { addSubsciptionQueue, removeSubsciptionQueue } from 'src/app/store/slices/subscriptions/slice';
import {
  CreatePositionCategory, EditPositionsSummaryTrades, PositionsSummaryTrades, TradeList,
} from './types';
import { OverlaySaveParamsResponse } from '../../trade/components/interfaces';

export type TradeListParams = {
  sub_account_id?: number;
  exchange_id?: number;
  trading_type?: TradingType;
  from_date?: string | null;
  to_date?: string | null;
  symbol?: string;
  side?: 'LONG' | 'SHORT'
  pnl_percent?: [number | null, number | null];
  leverage?: [number | null, number | null];
  profit?: [number | null, number | null];
  quantity?: [number | null, number | null];
  quantity_usdt?: [number | null, number | null];
  duration?: [number | null, number | null];
  category?: string
}

export type FetchParamsTradeList = {
  query: {
    limit: number;
    page: number;
  },
  body: TradeListParams
}

export type FetchPositionsSummaryTradesParams = {
  position_summary_id: string,
  sub_account_id: number
}

export type CreatePositionCategoryResponse = {
  categories: string[],
  id: string
}

export type PositionSummaryPublicParams = {
  sub_account_id: number,
  position_summary_id: string,
  is_visible: boolean
}

export type FetchFiguresParams = {
  sub_account_id: number
  position_summary_id: string
}

export const subscribeTradeSummary = () => {
  const { dispatch } = store;

  const intervalId = setInterval(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      devConsoleLog('Подписка на PositionsSummaryTrades');

      const subscribeMessage: ISubscribeEvent = {
        event: EInputEventType.SUBSCRIBE,
        metadata: {
          event_type: EOutputEventType.TRADES_SUMMARY,
        },
      };

      socket.send(JSON.stringify(subscribeMessage));
      dispatch(addSubsciptionQueue(subscribeMessage));
      clearInterval(intervalId);
    }
  }, 500);
};

export const unsubscribeTradeSummary = () => {
  const { dispatch } = store;

  if (socket && socket.readyState === WebSocket.OPEN) {
    devConsoleLog('Отписка от PositionsSummaryTrades');

    const unsubscribeMessage: IUnsubscribeEvent = {
      event: EInputEventType.UNSUBSCRIBE,
      metadata: {
        event_type: EOutputEventType.TRADES_SUMMARY,
      },
    };

    socket.send(JSON.stringify(unsubscribeMessage));
    dispatch(removeSubsciptionQueue(unsubscribeMessage.metadata.event_type));
  }
};

export const subscribeTradeList = () => {
  const { dispatch } = store;

  const intervalId = setInterval(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      devConsoleLog('Подписка на TradeList');

      const subscribeMessage: ISubscribeEvent = {
        event: EInputEventType.SUBSCRIBE,
        metadata: {
          event_type: EOutputEventType.POSITIONS_SUMMARY,
        },
      };

      socket.send(JSON.stringify(subscribeMessage));
      dispatch(addSubsciptionQueue(subscribeMessage));
      clearInterval(intervalId);
    }
  }, 500);
};

export const unsubscribeTradeList = () => {
  const { dispatch } = store;

  if (socket && socket.readyState === WebSocket.OPEN) {
    devConsoleLog('Отписка от TradeList');

    const unsubscribeMessage: IUnsubscribeEvent = {
      event: EInputEventType.UNSUBSCRIBE,
      metadata: {
        event_type: EOutputEventType.POSITIONS_SUMMARY,
      },
    };

    socket.send(JSON.stringify(unsubscribeMessage));
    dispatch(removeSubsciptionQueue(unsubscribeMessage.metadata.event_type));
  }
};

export const subscribeFundingFees = () => {
  const { dispatch } = store;

  const intervalId = setInterval(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      devConsoleLog('Подписка на FundingFees');

      const subscribeMessage: ISubscribeEvent = {
        event: EInputEventType.SUBSCRIBE,
        metadata: {
          event_type: EOutputEventType.FUNDING_FEES,
        },
      };

      socket.send(JSON.stringify(subscribeMessage));
      dispatch(addSubsciptionQueue(subscribeMessage));
      clearInterval(intervalId);
    }
  }, 500);
};

export const unsubscribeFundingFees = () => {
  const { dispatch } = store;

  if (socket && socket.readyState === WebSocket.OPEN) {
    devConsoleLog('Отписка от FundingFees');

    const unsubscribeMessage: IUnsubscribeEvent = {
      event: EInputEventType.UNSUBSCRIBE,
      metadata: {
        event_type: EOutputEventType.FUNDING_FEES,
      },
    };

    socket.send(JSON.stringify(unsubscribeMessage));
    dispatch(removeSubsciptionQueue(unsubscribeMessage.metadata.event_type));
  }
};

export const fetchCreatePositionCategory = createAsyncThunk(
  'tradeList/fetchCreatePositionCategory',
  async (params: CreatePositionCategory, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<CreatePositionCategoryResponse[]> = await $api.post(ROUTE_PATH.positionsSummary.categories, params);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchTradeList = createAsyncThunk(
  'tradeList/fetchTradingList',
  async ({ params, signal }: { params: FetchParamsTradeList, signal?: AbortSignal }, { rejectWithValue }) => {
    try {
      const response = await $api.post<TradeList>(ROUTE_PATH.positionsSummary.list, params.body, {
        params: {
          limit: params.query.limit.toString(),
          page: params.query.page.toString(),
        },
        signal,
      });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchPositionsSummaryTrades = createAsyncThunk(
  'tradeList/fetchPositionsSummaryTrades',
  async (params: FetchPositionsSummaryTradesParams, { rejectWithValue }) => {
    try {
      const response = await $api.get<PositionsSummaryTrades>(ROUTE_PATH.positionsSummary.id, { params });

      const { data } = response;

      if (data === undefined) throw response;
      subscribeTradeSummary();
      subscribeFundingFees();

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchEditPositionsSummary = createAsyncThunk(
  'tradeList/fetchEditPositionsSummary',
  async (params: EditPositionsSummaryTrades, { rejectWithValue }) => {
    try {
      const response = await $api.put<TradeList>(ROUTE_PATH.positionsSummary.get, params);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchPositionSummaryPublicVisible = createAsyncThunk(
  'tradeList/fetchPositionSummaryPublicVisible',
  async (params: PositionSummaryPublicParams, { rejectWithValue }) => {
    try {
      const response = await $api.put(ROUTE_PATH.positionsSummary.public, params);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchPositionSummaryPublicCreate = createAsyncThunk(
  'tradeList/fetchPositionSummaryPublicCreate',
  async (params: PositionSummaryPublicParams, { rejectWithValue }) => {
    try {
      const response = await $api.post(ROUTE_PATH.positionsSummary.public, params);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchFigures = createAsyncThunk(
  'tradeList/fetchFigures',
  async (params: FetchFiguresParams, { rejectWithValue }) => {
    try {
      const response = await $api.get<OverlaySaveParamsResponse[]>(ROUTE_PATH.positionsSummary.figures, {
        params: {
          sub_account_id: params.sub_account_id,
          position_summary_id: params.position_summary_id,
        },
      });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchDownloadPositionsSummary = createAsyncThunk(
  'tradeList/fetchDownloadPositionsSummary',
  async (params: TradeListParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<Blob> = await $api.post(ROUTE_PATH.positionsSummary.export, params, {
        responseType: 'blob',
      });

      const { data } = response;

      if (!data) throw response;

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'trading_journal_report.csv';
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);
