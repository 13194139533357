import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './portal-modal.module.scss';

interface IPortaModalProps {
  children: React.ReactNode;
  opened: boolean;
  overlayBlur?: boolean;
  closeOnClickOutside?: boolean;
}

const PortalModal: React.FC<IPortaModalProps> = ({
  children, opened, overlayBlur, closeOnClickOutside,
}) => {
  const [container] = useState(() => {
    const div = document.createElement('div');
    div.classList.add(styles.fullScreen);
    if (overlayBlur) {
      div.classList.add(styles.blur);
    }

    return div;
  });

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.classList.contains(styles.fullScreen)) {
      const closeButton = container.querySelector('.btn-close') as HTMLElement;
      if (closeButton) {
        closeButton.click();
      }
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      const closeButton = container.querySelector('.btn-close') as HTMLElement;
      if (closeButton) {
        closeButton.click();
      }
    }
  };

  useEffect(() => {
    if (!opened) return undefined;

    document.body.appendChild(container);

    if (closeOnClickOutside) {
      container.addEventListener('click', handleClickOutside);
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      if (closeOnClickOutside) {
        container.removeEventListener('click', handleClickOutside);
      }
      window.removeEventListener('keydown', handleKeyDown);
      document.body.removeChild(container);
    };
  }, [opened, container, closeOnClickOutside]);

  if (!opened) return null;

  return ReactDOM.createPortal(
    <div className={styles.overlay}>
      <div className={styles.modalContent}>{children}</div>
    </div>,
    container,
  );
};

export default PortalModal;
