import { useTranslation } from 'react-i18next';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';
import useMobile from 'src/shared/libs/hooks/use-mobile';
import Withdrawals from './components/withdrawal/withdrawals';
import Replenishment from './components/replenishment/replenishment';
import TransfersAdaptive from './adaptive/transfers-adaptive';
import './transfers.scss';

const pageTitle = 'transfers';

const TransfersPage = () => {
  const { t } = useTranslation();
  useChangePageTitle(pageTitle);

  const isMobile = useMobile();

  return (
    <div className="transfers-page-wrapper">
      <div className="section-name">
        <h1>{t('transfers')}</h1>
      </div>

      {isMobile ? <TransfersAdaptive /> : (
        <div className="section-content">
          <Replenishment />
          <Withdrawals />
        </div>
      )}
    </div>
  );
};

export default TransfersPage;
