import { $api } from 'src/shared/api/axios.instance';
import { socket } from 'src/shared/api/websocket.instance';
import { AxiosError } from 'axios';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { devConsoleLog } from 'src/shared/libs/helpers/helper.lib';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ISubscribeEvent,
  EInputEventType,
  EOutputEventType,
  IUnsubscribeEvent,
} from 'src/shared/types/ws-interfaces';
import { TradingType } from 'src/shared/types/global-types';
import {
  ApiKey,
  SubAccountType,
  SubAccountSettings,
  SubAccountAsset,
  SubAccountsResponse,
} from './types';
import { store } from '../../store';
import { addSubsciptionQueue, removeSubsciptionQueue } from '../subscriptions/slice';

export type SubAccountsAssetsParams = {
  sub_account_id?: number
  exchange_id?: number
}

export type CreateSubAccountParams = {
  exchange_id: number
  user_name: string
  invite_code?: string
}

export type CreateApiKey = {
  exchange_id: number
  sub_account_id: number
  user_name: string
  permissions: number[]
  ip_addresses: string[]
}

export type CreateApiKeyParams = {
  newApiKey: CreateApiKey
  verification_id: number
}

export type EditApiKey = {
  secret_id: number
  user_name: string
  permissions: number[]
  ip_addresses: string[]
}

export type EditApiKeyParams = {
  editApiKey: EditApiKey
  verification_id: number
}

export type RemoveApiKeyParams = {
  secret_id: number
  verification_id: number
}

export type EditSubAccount = {
  exchange_id: number
  user_name: string
  is_favorite: boolean
}

export type EditSubAccountParams = {
  editSubAccount: EditSubAccount
  subAccountId: number
}

export type ResetPnl = {
  sub_account_id: number
  trading_type: TradingType
}

export type CollateralStatus = {
  sub_account_id: number
  asset_id: number
  collateralizable: boolean
}

export const subscribeSubAccountAsset = () => {
  const { dispatch } = store;

  const intervalId = setInterval(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      devConsoleLog('Подписка на SubAccountAssets');

      const subscribeMessage: ISubscribeEvent = {
        event: EInputEventType.SUBSCRIBE,
        metadata: {
          event_type: EOutputEventType.SUB_ACCOUNT_ASSETS,
        },
      };

      socket.send(JSON.stringify(subscribeMessage));
      dispatch(addSubsciptionQueue(subscribeMessage));
      clearInterval(intervalId);
    }
  }, 500);
};

export const unsubscribeSubAccountAsset = () => {
  const { dispatch } = store;

  if (socket && socket.readyState === WebSocket.OPEN) {
    devConsoleLog('Отписка от SubAccountAssets');

    const unsubscribeMessage: IUnsubscribeEvent = {
      event: EInputEventType.UNSUBSCRIBE,
      metadata: {
        event_type: EOutputEventType.SUB_ACCOUNT_ASSETS,
      },
    };

    socket.send(JSON.stringify(unsubscribeMessage));
    dispatch(removeSubsciptionQueue(unsubscribeMessage.metadata.event_type));
  }
};

export const subscribeInstrument = (data: number[]) => {
  const { dispatch } = store;

  const intervalId = setInterval(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      devConsoleLog('Подписка на SubAccountAssetInstrument');

      const subscribeMessage: ISubscribeEvent = {
        event: EInputEventType.SUBSCRIBE,
        metadata: {
          event_type: EOutputEventType.INSTRUMENTS,
          data,
        },
      };

      socket.send(JSON.stringify(subscribeMessage));
      dispatch(addSubsciptionQueue(subscribeMessage));
      clearInterval(intervalId);
    }
  }, 500);
};

export const unsubscribeInstrument = () => {
  const { dispatch } = store;

  if (socket && socket.readyState === WebSocket.OPEN) {
    devConsoleLog('Отписка от SubAccountAssetsInstrument');

    const unsubscribeMessage: IUnsubscribeEvent = {
      event: EInputEventType.UNSUBSCRIBE,
      metadata: {
        event_type: EOutputEventType.INSTRUMENTS,
      },
    };

    socket.send(JSON.stringify(unsubscribeMessage));
    dispatch(removeSubsciptionQueue(unsubscribeMessage.metadata.event_type));
  }
};

export const fetchSubAccounts = createAsyncThunk(
  'subAccounts/fetchSubAccounts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get<SubAccountsResponse>(ROUTE_PATH.subAccounts.subAccounts);
      const { data } = response;

      if (data === undefined) throw response;

      return data.items;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data);
      return rejectWithValue(error);
    }
  },
);

export const fetchCreateSubAccount = createAsyncThunk(
  'subAccounts/fetchCreateSubAccount',
  async (newSubAccount: CreateSubAccountParams, { rejectWithValue }) => {
    try {
      const response = await $api.post<SubAccountType>(ROUTE_PATH.subAccounts.subAccounts, newSubAccount);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchEditSubAccount = createAsyncThunk(
  'subAccounts/fetchEditSubAccount',
  async ({ editSubAccount, subAccountId }: EditSubAccountParams, { rejectWithValue }) => {
    try {
      const response = await $api.put<SubAccountType>(
        ROUTE_PATH.subAccounts.subAccounts,
        editSubAccount,
        {
          headers: {
            'sub-account-id': subAccountId,
          },
        },
      );

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error);
      return rejectWithValue(error);
    }
  },
);

export const fetchSubAccountsAssets = createAsyncThunk(
  'subAccounts/fetchSubAccountsAssets',
  async (params: SubAccountsAssetsParams, { rejectWithValue }) => {
    try {
      const config: any = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {}, // Add params object for query parameters
      };

      // Add query parameters if they exist
      if (params.sub_account_id) {
        config.params.sub_account_id = params.sub_account_id;
      }

      if (params.exchange_id) {
        config.params.exchange_id = params.exchange_id;
      }

      const response = await $api.get<SubAccountAsset[]>(ROUTE_PATH.subAccounts.assets, config);

      const { data } = response;

      if (data === undefined) throw response;

      subscribeSubAccountAsset();

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data.detail);
      }
      return rejectWithValue(error);
    }
  },
);

export const fetchSubAccountsAssetsTransfers = createAsyncThunk(
  'subAccounts/fetchSubAccountsAssetsTransfers',
  async (params: SubAccountsAssetsParams, { rejectWithValue }) => {
    try {
      const config: any = {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {}, // Add params object for query parameters
      };

      // Add query parameters if they exist
      if (params.sub_account_id) {
        config.params.sub_account_id = params.sub_account_id;
      }

      if (params.exchange_id) {
        config.params.exchange_id = params.exchange_id;
      }

      const response = await $api.get<SubAccountAsset[]>(ROUTE_PATH.subAccounts.assets, config);
      const { data } = response;

      if (data === undefined) throw response;

      subscribeSubAccountAsset();

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data.detail);
      }
      return rejectWithValue(error);
    }
  },
);

export const fetchApiKey = createAsyncThunk(
  'subAccounts/fetchApiKey',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get<ApiKey[]>(ROUTE_PATH.subAccounts.secrets);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data);
      return rejectWithValue(error);
    }
  },
);

export const fetchCreateApiKey = createAsyncThunk(
  'subAccounts/fetchCreateApiKey',
  async (params: CreateApiKeyParams, { rejectWithValue }) => {
    try {
      const response = await $api.post(ROUTE_PATH.subAccounts.secrets, params.newApiKey, {
        headers: {
          'verification-id': params.verification_id,
        },
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data as ApiKey;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchEditApiKey = createAsyncThunk(
  'subAccounts/fetchEditApiKey',
  async (params: EditApiKeyParams, { rejectWithValue }) => {
    try {
      const response = await $api.put<ApiKey>(ROUTE_PATH.subAccounts.secrets, params.editApiKey, {
        headers: {
          'verification-id': params.verification_id,
        },
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchRemoveApiKey = createAsyncThunk(
  'subAccounts/fetchRemoveApiKey',
  async (params: RemoveApiKeyParams, { rejectWithValue }) => {
    try {
      const response = await $api.delete(ROUTE_PATH.subAccounts.secrets, {
        data: { secret_id: params.secret_id },
        headers: {
          'verification-id': params.verification_id,
        },
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data);
      return rejectWithValue(error);
    }
  },
);

export const fetchSubAccountSettings = createAsyncThunk(
  'subAccounts/fetchSubAccountSettings',
  async (subAccountId: number, { rejectWithValue }) => {
    try {
      const response = await $api.get<SubAccountSettings>(
        ROUTE_PATH.subAccounts.setting,
        {
          headers: {
            'sub-account-id': subAccountId,
          },
        },
      );
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchResetPnl = createAsyncThunk(
  'subAccounts/fetchSubAccountSettings',
  async (params: ResetPnl, { rejectWithValue }) => {
    try {
      const response = await $api.post(ROUTE_PATH.subAccounts.resetPnl, params);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchSetCollateralStatus = createAsyncThunk(
  'subAccounts/fetchSetCollateralStatus',
  async (params: CollateralStatus, { rejectWithValue }) => {
    try {
      const response = await $api.post(ROUTE_PATH.subAccounts.setCollateralStatus, params);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);
