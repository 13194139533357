import { useEffect } from 'react';
import { Balance } from 'src/widgets/balance';
import { TransfersBlock } from 'src/widgets/transfers';
import { TransfersHistory } from 'src/widgets/transfer-history';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';
import { unsubscribeSubAccountAsset } from 'src/app/store/slices/sub-accounts/thunks';

import useMobile from 'src/shared/libs/hooks/use-mobile';
import SubaccountsContainer from './components/subaccounts-container/subaccounts-container';
import AccountsPageAdaptive from './adaptive/accounts-adaptive';
import styles from './accounts.module.scss';

const pageTitle = 'accounts';

const AccountsPage = () => {
  useChangePageTitle(pageTitle);

  const isMobile = useMobile();

  useEffect(() => () => {
    unsubscribeSubAccountAsset();
  }, []);

  return isMobile ? <AccountsPageAdaptive /> : (
    <div className={styles.subAccountsWrapper}>
      <div className={styles.content}>
        <SubaccountsContainer />
        <TransfersHistory />
      </div>
      <div className={styles.aside}>
        <Balance />
        <TransfersBlock />
      </div>
    </div>
  );
};

export default AccountsPage;
