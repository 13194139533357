import { useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import {
  Burger, Indicator, Modal, Tooltip,
} from '@mantine/core';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/app/store/store';

import { socket } from 'src/shared/api/websocket.instance';
import { useTheme } from 'src/shared/libs/hooks/use-theme';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { clearStoreRedux, join, scrollToTop } from 'src/shared/libs/helpers/helper.lib';
import { hideAccountBalance } from 'src/pages/settings/model/slice';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { PositionCloseDetail } from 'src/pages/trading/model/types';
import { fetchOrderCancelAll, fetchPositionsCloseAll } from 'src/pages/trading/model/thunks';
import { fetchChangeTradeSettings } from 'src/pages/settings/model/thunks';

import { ReactComponent as BellIconSvg } from 'src/shared/assets/images/header/bell.svg';
import { ReactComponent as MoonIconSvg } from 'src/shared/assets/images/header/moon.svg';
import { ReactComponent as LightIconSvg } from 'src/shared/assets/images/header/light.svg';
import { ReactComponent as ClosePositionsOrdersSvg } from 'src/shared/assets/images/header/closePositions.svg';
import { ReactComponent as ViewIconSvg } from 'src/shared/assets/images/header/view.svg';
import { ReactComponent as EyeClosedSvg } from 'src/shared/assets/images/auth/eye-closed.svg';
import { ReactComponent as LogOutSvg } from 'src/shared/assets/images/header/log-out.svg';
import { ReactComponent as LogotypeIconSvg } from 'src/shared/assets/images/header/logotype-theme.svg';

import useAlert from 'src/shared/libs/hooks/use-alert';
import useMobile from 'src/shared/libs/hooks/use-mobile';
import AuthService from 'src/services/auth.service';
import ExitModalContent from 'src/entities/exit-modal-content/exit-modal-content';
import LanguageSelection from 'src/entities/language-selection/language-selection';
import ClosePositionsContent from 'src/entities/close-positions-content/close-positions-content';
import useLogotype from 'src/shared/assets/logo/use-logotype';

import { selectNotifications } from '../notifications/model/selectors';
import AdaptiveMenu from '../adaptive-menu/adaptive-menu';

import './header.scss';

interface IHeaderProps {
  setAreShownNotifications: (value: boolean) => void;
}

const Header = ({ setAreShownNotifications }: IHeaderProps) => {
  const isMobile = useMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  // const logotype = useLogotype();

  const { t } = useTranslation();
  const { theme, setTheme } = useTheme();
  const { setAlertMessage } = useAlert();

  const [opened, { toggle, close }] = useDisclosure();
  const [openedLogout, { open: openLogoutModal, close: closeLogoutModal }] = useDisclosure(false);
  const [openedClosePostions, { open: openClosePositionsModal, close: closePostionsModal }] = useDisclosure(false);

  const [isAnimating, setIsAnimating] = useState(false);
  const [isAnimatingEye, setIsAnimatingEye] = useState(false);

  const { notifications } = useSelector(selectNotifications);
  const balanceVisible = useSelector(selectHiddenBalance);

  const readStatus: boolean = notifications ? notifications.items.some((message) => !message.read) : false;

  const handleSetTheme = () => {
    if (theme === EThemeMode.LIGHT) setTheme(EThemeMode.DARK);
    if (theme === EThemeMode.DARK) setTheme(EThemeMode.LIGHT);

    setIsAnimating(true);

    setTimeout(() => {
      setIsAnimating(false);
    }, 200);
  };

  const handleHiddenBalances = async () => {
    const visible = !balanceVisible;

    const { payload } = await dispatch(fetchChangeTradeSettings({ hide_account_balance: visible }));

    if (payload) {
      dispatch(hideAccountBalance(visible));
      setIsAnimatingEye(true);

      setTimeout(() => {
        setIsAnimatingEye(false);
      }, 200);
    }
  };

  const handleLogout = () => {
    try {
      socket?.close();
      AuthService.logOut();
      localStorage.clear();
      sessionStorage.clear();
      clearStoreRedux();
    } catch (error) {
      console.debug(t('web socket close error'), error);
    }
  };

  const closeAllPositions = async () => {
    const { payload } = await dispatch(fetchPositionsCloseAll());

    if (Array.isArray(payload) && payload.length === 0) {
      setAlertMessage(t('positions closed'), 'success');
    } else if (Array.isArray(payload) && payload.length > 0) {
      payload.forEach((detail: PositionCloseDetail) => {
        const message = `asset: ${detail.symbol}, detail: ${detail.detail}`;
        setAlertMessage(message, 'error');
      });
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else {
      setAlertMessage('internal error', 'error');
    }
  };

  const closeAllOrders = async () => {
    const { payload } = await dispatch(fetchOrderCancelAll());

    if (Array.isArray(payload) && payload.length === 0) {
      setAlertMessage(t('orders canceled'), 'success');
    } else if (Array.isArray(payload) && payload.length > 0) {
      payload.forEach((detail: PositionCloseDetail) => {
        const message = `asset: ${detail.symbol}, detail: ${detail.detail}`;
        setAlertMessage(message, 'error');
      });
    } else if (typeof payload === 'string') {
      setAlertMessage(payload, 'error');
    } else {
      setAlertMessage('internal error', 'error');
    }
  };

  const handleClosePositionsAndOrders = () => {
    closePostionsModal();
    closeAllPositions();
    closeAllOrders();
  };

  const onClickLogo = () => {
    navigate('/trading');
    scrollToTop();
  };

  useEffect(() => {
    if (!isMobile) close();
  }, [isMobile]);

  return (
    <header className="header">
      <nav>
        <Link className={join(pathname === '/screener' && 'active')} to="/screener">{t('screener')}</Link>
        <Link className={join(pathname === '/trading-journal/trade-list' && 'active')} to="/trading-journal/trade-list">{t('diary')}</Link>
        <Link className={join(pathname === '/algo-trading' && 'active')} to="/algo-trading">{t('algoTrading')}</Link>
        <Link className={join(pathname === '/alerts' && 'active')} to="/alerts">{t('alerts')}</Link>
        <Link className={join(pathname === '/news' && 'active')} to="/news">{t('news')}</Link>
      </nav>
      {isMobile && <Burger className="burger-menu" opened={opened} onClick={toggle} aria-label="Toggle navigation" />}
      <LogotypeIconSvg onClick={onClickLogo} className="header-logo-mobile" />
      {/* <button onClick={onClickLogo} className="header-logo-mobile">
        <img src={logotype} alt="logo" />
      </button> */}

      <div className="page-customisations">
        {!isMobile && <LanguageSelection position="relative" />}

        {isMobile && <ClosePositionsOrdersSvg onClick={openClosePositionsModal} className="close-positions-btn" />}

        <Tooltip disabled={isMobile} zIndex={3001} withArrow label={theme === EThemeMode.LIGHT ? t('dark theme') : t('light theme')} position="bottom" offset={9}>
          <button className={`theme-button ${isAnimating ? 'animating' : ''}`} onClick={handleSetTheme}>
            {theme === EThemeMode.LIGHT && <MoonIconSvg />}
            {theme === EThemeMode.DARK && <LightIconSvg className="light-icon" />}
          </button>

        </Tooltip>

        <Tooltip disabled={isMobile} zIndex={3001} withArrow className={`eye-button ${isAnimatingEye ? 'animating' : ''}`} label={balanceVisible ? t('show balances') : t('hide balances')} position="bottom" offset={9}>
          <button onClick={handleHiddenBalances}>
            { balanceVisible ? <ViewIconSvg /> : <EyeClosedSvg />}
          </button>
        </Tooltip>

        <Indicator disabled={!readStatus} className="indicator" processing color="red" size={10}>
          <Tooltip disabled={isMobile} zIndex={3001} withArrow label={t('notifications')} position="bottom" offset={9}>
            <button
              className="bell"
              onClick={() => setAreShownNotifications(true)}
            >
              <BellIconSvg />
            </button>
          </Tooltip>
        </Indicator>

        {!isMobile && (
          <Tooltip disabled={isMobile} zIndex={3001} withArrow label={t('log out')} position="bottom" offset={9}>
            <button onClick={openLogoutModal}>
              <LogOutSvg onClick={openLogoutModal} />
            </button>
          </Tooltip>
        )}

        <AdaptiveMenu onLogOut={openLogoutModal} close={close} visible={isMobile} opened={opened} />
      </div>

      <Modal
        title={<span>{t('confirm logout')}</span>}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        radius={16}
        size="md"
        opened={openedLogout}
        onClose={closeLogoutModal}
        className={join('modal-custom-overflow', 'header-title-logout')}
      >
        <ExitModalContent logOut={handleLogout} onClose={closeLogoutModal} />
      </Modal>

      <Modal
        title={<span>{t('close all positions and cancel all orders')}</span>}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        radius={16}
        size="sm"
        opened={openedClosePostions}
        onClose={closePostionsModal}
        className={join('modal-custom-overflow', 'header-title-logout')}
      >
        <ClosePositionsContent onSubmit={handleClosePositionsAndOrders} onClose={closePostionsModal} />
      </Modal>
    </header>
  );
};

export default Header;
