import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/app/store/store';
import { setAuth } from 'src/pages/auth/login/model/slice';
import { selectAuth } from 'src/pages/auth/login/model/selectors';
import { socket } from 'src/shared/api/websocket.instance';
import { clearStoreRedux } from '../helpers/helper.lib';

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isAuth, isWelcomePage } = useSelector(selectAuth);

  const handleLogout = useCallback(() => {
    try {
      socket?.close();
      localStorage.clear();
      sessionStorage.clear();
      dispatch(setAuth(false));
      clearStoreRedux();
    } catch (error) {
      console.debug(t('web socket close error'), error);
    }
  }, [dispatch, t]);

  return {
    isAuth,
    isWelcomePage,
    logOut: handleLogout,
  };
};
