import qs from 'qs';
import { unwrapResult } from '@reduxjs/toolkit';
import React, {
  FormEventHandler,
  memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

import { Wallet } from 'src/app/store/slices/wallets/types';
import { selectUser } from 'src/app/store/slices/user/selectors';
import { clearWallets } from 'src/app/store/slices/wallets/slice';
import { fetchWallets } from 'src/app/store/slices/wallets/thunks';
import { selectWallets } from 'src/app/store/slices/wallets/selectors';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { selectExchanges } from 'src/app/store/slices/exchanges/selectors';
import { Assets, Network } from 'src/app/store/slices/assets/types';
import { selectVerifications } from 'src/app/store/slices/verifications/selectors';
import { removeVerifications } from 'src/app/store/slices/verifications/slice';
import { fetchSubAccountsAssets } from 'src/app/store/slices/sub-accounts/thunks';
import { RootState, useAppDispatch } from 'src/app/store/store';
import { selectWalletAttributes } from 'src/app/store/slices/assets/selectors';
import { EVerificationType, Verification } from 'src/app/store/slices/verifications/types';
import { selectSubAccountsAssets, selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';
import { clearAssetsSlice, setCurrentAssetOutput, setCurrentNetworkOutput } from 'src/app/store/slices/assets/slice';
import { BalanceType } from 'src/entities/balance-type';
import { ETradingType, EStatus, Nullable } from 'src/shared/types/global-types';
import {
  decimalizeQuantity, getSubAccountImage, handleSupportTelegram, hideEmail, join, minusValues, sortSubAccountsByFavorite, toFixed, toFixedDecimalPrecision,
  typeSubAccountBack,
} from 'src/shared/libs/helpers/helper.lib';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import {
  WalletAttributesParams,
  Withdrawal, fetchWalletAttributes, fetchWithdrawals,
} from 'src/app/store/slices/assets/thunks';
import { ReactComponent as AuthenticatorIconSvg } from 'src/shared/assets/images/profile/authenticator.svg';
import Logotype from 'src/shared/assets/logo/favicon-logo.png';
import {
  Confirm,
  fetchVerifications,
  VerificationConfirm,
  fetchVerificationsConfirm,
  VerificationsSendEmailCode,
  VerificationsSendPhoneCode,
  VerificationsSendWhatsAppCode,
  fetchVerificationsSendEmailCode,
  fetchVerificationsSendPhoneCode,
  fetchVerificationsSendWhatsAppCode,
} from 'src/app/store/slices/verifications/thunks';
import Hint from 'src/shared/ui/help/hint';
import Button from 'src/shared/ui/button/button/button';
import Select from 'src/shared/ui/select/select';
import Divisor from 'src/shared/ui/divisor/divisor/divisor';
import useAlert from 'src/shared/libs/hooks/use-alert';
import InputCode from 'src/shared/ui/input/input-code/input-code';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import InputText from 'src/shared/ui/input/input-text/input-text';
import InputAction from 'src/pages/auth/components/inputs-auth/input-action';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import DefaultIconSvg from 'src/shared/assets/images/exchange/binance.svg';
import AdviceParagraph from 'src/shared/ui/paragraph/advice-paragraph/advice-paragraph';
import VerificationIssues from 'src/entities/verification-issues/verification-issues';
import { ERROR_MSG_LIST } from '../../constants';
import '../../transfers.scss';

const Withdrawals = memo(() => {
  const queryString = window.location.search;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { setAlertMessage } = useAlert();
  const [openedVerify, { open: openVerify, close: closeVerify }] = useDisclosure(false);

  const currentAsset = useSelector((state: RootState) => state.assets.currentAssetOutput);
  const currentNetwork = useSelector((state: RootState) => state.assets.currentNetworkOutput);
  const statusWithdrawal = useSelector((state: RootState) => state.assets.statusWithdrawal);

  const subAccounts = useSelector(selectSubAccounts);
  const balanceVisible = useSelector(selectHiddenBalance);
  const subAccountAssets = useSelector(selectSubAccountsAssets);
  const walletAttributes = useSelector(selectWalletAttributes);
  const { exchanges } = useSelector(selectExchanges);
  const { user, settings } = useSelector(selectUser);
  const { wallets, exists, status } = useSelector(selectWallets);
  const { verification, statusConfirm } = useSelector(selectVerifications);

  const verifyFormRef = useRef<HTMLFormElement>(null);

  const [subAccountSum, setSubAccoundSum] = useState('');
  const [subAccoundId, setSubAccoundId] = useState<Nullable<number>>(null);
  const [currentSubAccount, setCurrentSubAccount] = useState<Nullable<SubAccountType>>(null);
  const [currentAssetSymbol, setCurrentAssetSymbol] = useState('');
  const [searchAssetSymbol, setSearchAssetSymbol] = useState('');

  // output state
  const [quantity, setQuantity] = useState('');
  const [divisorSum, setDivisorSum] = useState('');
  const [currentWallet, setCurrentWallet] = useState<Nullable<Wallet>>(null);
  const [verifyStatusWithdrawal, setVerifyStatusWithdrawal] = useState(false);

  // verify modal from states
  const [codeEmail, setCodeEmail] = useState('');
  const [codePhone, setCodePhone] = useState('');
  const [codeGA, setCodeGA] = useState('');

  const [verifyInputError, setVerifyInputError] = useState({
    emailError: false,
    phoneError: false,
    gaError: false,
  });

  const getSpotWalletAccount = useCallback((id: Nullable<number>) => {
    let qunatity = 0;
    if (!id) return qunatity;

    if (subAccountAssets && currentAsset) {
      const assetsBalance = subAccountAssets.find((item) => item.asset.symbol === currentAsset.asset.symbol && item.sub_account_id === id && item.asset_type === ETradingType.spot);

      qunatity = assetsBalance ? Number(decimalizeQuantity(minusValues(assetsBalance.quantity, assetsBalance.locked), currentAsset.asset.symbol)) : 0;
      qunatity = assetsBalance ? Number(minusValues(assetsBalance.quantity, assetsBalance.locked)) : 0;
    }
    return toFixedDecimalPrecision(qunatity.toString());
  }, [subAccountAssets, currentAsset]);

  const getWhiteWallets = async () => {
    const parsedParams = qs.parse(queryString, { ignoreQueryPrefix: true });

    const asset = {
      id: parsedParams?.asset_id,
      symbol: parsedParams?.asset_symbol,
    };
    /**
     * This function is intended for autopickup of a wallet, when the user on the SubAccount page clicks to output the selected asset
     * The logic of the function:
     * When we know what currency we are withdrawing, the available wallet is searched, moreover,
     * if there are several available wallets, the wallet that can be used to withdraw funds with minimum commission is automatically selected.
     */
    const fetchParams = Number.isInteger(Number(asset.id)) ? { asset_id: Number(asset.id) } : {};

    try {
      const response = await dispatch(fetchWallets(fetchParams));
      const result = unwrapResult(response);
      const { items: whiteWallets } = result;

      if (whiteWallets?.length) {
        setCurrentWallet(whiteWallets[0]);
      }
    } catch (error) {
      console.debug('Error fetching wallets:', error);
    }
  };

  const handleFetchVerification = async (verificationType: EVerificationType) => {
    let result = false;

    const { payload } = await dispatch(fetchVerifications(verificationType));
    const responseVerification = payload as Verification;

    if (payload === 'TOO_MANY_REQUESTS') {
      setAlertMessage('too many attempts 2', 'warning');
      return false;
    }

    if (responseVerification && user) {
      const sendPhoneCodeParams: VerificationsSendPhoneCode = {
        phoneNumber: user.phone_number,
        verification_id: responseVerification.verification_id,
      };

      const sendWhatsAppParams: VerificationsSendWhatsAppCode = {
        phoneNumber: user.phone_number,
        verification_id: responseVerification.verification_id,
      };

      const sendEmailCodeParams: VerificationsSendEmailCode = {
        email: user.email,
        verification_id: responseVerification.verification_id,
      };

      if (responseVerification.methods.includes('phone')) {
        const { payload } = await dispatch(fetchVerificationsSendPhoneCode(sendPhoneCodeParams));

        if (payload) {
          result = true;
        }
      }
      if (responseVerification.methods.includes('whatsapp')) {
        const { payload } = await dispatch(fetchVerificationsSendWhatsAppCode(sendWhatsAppParams));

        if (payload) {
          result = true;
        }
      }
      if (responseVerification.methods.includes('email')) {
        dispatch(fetchVerificationsSendEmailCode(sendEmailCodeParams));
        if (payload) {
          result = true;
        }
      }
      if (responseVerification.methods.includes('ga')) {
        if (payload) {
          result = true;
        }
      }
    } else {
      setAlertMessage('internal error', 'error');
    }

    return result;
  };

  const handleAddNewWallet = () => navigate('/profile?addWallet=true');

  const handleSetCurrentAsset = (asset: Assets) => {
    if (asset.asset.id === currentAsset?.asset.id) return;

    navigate(window.location.pathname);

    const assetsBalance = subAccountAssets?.filter((item) => item.asset_id === asset.asset.id && item.sub_account_id === subAccoundId && item.asset_type === ETradingType.spot);

    if (assetsBalance && assetsBalance.length > 0 && currentAsset) {
      const firstBalanceItem = assetsBalance[0];
      if ('quantity' in firstBalanceItem) {
        const { quantity } = firstBalanceItem;
        setQuantity('');
        setDivisorSum('');
        setSubAccoundSum(toFixed(String(quantity), currentAsset.asset_network.withdraw_multiplicity));
      } else {
        // Handle case where quantity property is missing
      }
    }
    dispatch(setCurrentAssetOutput(asset));
  };

  const handleCurrentWallet = (whiteWallet: Wallet) => {
    setCurrentWallet(whiteWallet);
    navigate(window.location.pathname);
  };

  const handleSetCurrentNetwork = (network: Network) => dispatch(setCurrentNetworkOutput(network));

  const handleSetSubAccount = (subAccount: SubAccountType) => {
    navigate(window.location.pathname);

    setSubAccoundId(subAccount.id);
    setCurrentSubAccount(subAccount);
    setQuantity('');

    if (subAccountAssets && currentAsset) {
      const assetsBalance = subAccountAssets.find((item) => item.asset_id === currentAsset.asset.id && item.sub_account_id === subAccount.id && item.asset_type === 1);
      const balanceSpot = assetsBalance ? assetsBalance?.quantity : 0;

      setSubAccoundSum(toFixedDecimalPrecision(String(balanceSpot), currentAsset.asset_network.withdraw_multiplicity));
      setDivisorSum('0');
    }
  };

  const handleDivisorSum = (value: number) => {
    if (subAccountSum && currentAsset) {
      const percentage = (value / 100) * Number(subAccountSum);

      setDivisorSum(value.toFixed(1));
      setQuantity(toFixedDecimalPrecision(String(percentage), currentAsset.asset_network.withdraw_multiplicity));
    }
  };

  const handleSetQuantity = (value: string) => {
    const regex = /^([0-9]+([.][0-9]*)?|[.][0-9]+)?$/;

    if (!regex.test(value)) return;

    if (subAccountSum) {
      const parsedValue = parseFloat(value);
      const calculatedPercentage = (parsedValue / Number(subAccountSum)) * 100;

      setQuantity(value);
      setDivisorSum(toFixed(String(calculatedPercentage), 1));
    }
  };

  const handleVerifyTransfer = async () => {
    if (!currentAsset || !currentWallet) return;

    // TODO - нужно проверить адрес кошелька ByBit (regex прилетает null у Bybit). Временно убрали проверку адреса у Bybit.
    const withdrawalParams = {
      min: Number(currentAsset.asset_network.withdraw_min),
      max: Number(currentAsset.asset_network.withdraw_max),
      amount: Number(quantity),
      addressRegex: currentAsset.asset_network.address_regex ? new RegExp(currentAsset.asset_network.address_regex) : null,
    };

    if (withdrawalParams.amount < withdrawalParams.min) {
      setAlertMessage(`${t('min_withdrawal_amount')} - ${withdrawalParams.min}`, 'warning');
      return;
    }
    if (withdrawalParams.max > 0 && withdrawalParams.amount > withdrawalParams.max) {
      setAlertMessage(`${t('max_withdrawal_amount')} - ${withdrawalParams.max.toLocaleString()}`, 'warning');
      return;
    }
    if (withdrawalParams.addressRegex && !withdrawalParams.addressRegex.test(currentWallet.address)) {
      setAlertMessage('incorrect_wallet_address', 'error');
      return;
    }

    setVerifyStatusWithdrawal(true);

    const result = await handleFetchVerification(EVerificationType.MAKE_WITHDRAW);

    if (result === true) {
      openVerify();
      setVerifyStatusWithdrawal(false);
    } else {
      setVerifyStatusWithdrawal(false);
    }
  };

  const handleTransfer = async () => {
    if (currentAsset && subAccoundId && currentWallet && verification) {
      const withdrawalOptions: Withdrawal = {
        asset_symbol: currentAsset.asset.symbol,
        from_sub_account_id: subAccoundId,
        white_wallet_id: currentWallet.wallet_id,
        verification_id: verification.verification_id,
        quantity: Number(toFixed(String(quantity), currentAsset.asset_network.withdraw_multiplicity)),
      };

      try {
        const { payload } = await dispatch(fetchWithdrawals(withdrawalOptions));

        if (payload === true) {
          setAlertMessage('success', 'success');
          setQuantity('');
        } else {
          throw payload;
        }
      } catch (error: unknown) {
        if (typeof error === 'string') {
          const message = ERROR_MSG_LIST[error] || error;
          setAlertMessage(message, 'error');
        } else {
          setAlertMessage('internal error', 'error');
        }
      }
    }
  };

  const clearVerifyInputs = () => {
    setCodeEmail('');
    setCodePhone('');
    setCodeGA('');
  };

  const handleCloseVerify = () => {
    if (statusConfirm === EStatus.loading) return;

    dispatch(removeVerifications());
    clearVerifyInputs();
    setVerifyInputError({
      emailError: false,
      phoneError: false,
      gaError: false,
    });
    closeVerify();
  };

  const handleFetchCodeSms = () => {
    if (user && verification && settings) {
      if (settings.type_sms_code === 'phone') {
        const sendPhoneCodeParams: VerificationsSendPhoneCode = {
          phoneNumber: user.phone_number,
          verification_id: verification.verification_id,
        };
        dispatch(fetchVerificationsSendPhoneCode(sendPhoneCodeParams));
      } else {
        const sendWhatsAppParams: VerificationsSendWhatsAppCode = {
          phoneNumber: user.phone_number,
          verification_id: verification.verification_id,
        };
        dispatch(fetchVerificationsSendWhatsAppCode(sendWhatsAppParams));
      }
    }
  };

  const handleFetchCodeEmail = () => {
    if (user && verification) {
      const sendEmailCodeParams: VerificationsSendEmailCode = {
        email: user.email,
        verification_id: verification.verification_id,
      };

      dispatch(fetchVerificationsSendEmailCode(sendEmailCodeParams));
    }
  };

  const handleAutoSubmit = async () => {
    let checkSaveForm = false;
    const form = verifyFormRef.current;

    if (verification) {
      if (verification.methods.includes('email')) {
        setVerifyInputError((prev) => (
          { ...prev, emailError: codeEmail.trim() === '' }
        ));
      }
      if (verification.methods.includes('phone')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }
      if (verification.methods.includes('whatsapp')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }
      if (verification.methods.includes('ga')) {
        setVerifyInputError((prev) => (
          { ...prev, gaError: codeGA.trim() === '' }
        ));
      }
    }

    if (form) {
      const inputElements = form.querySelectorAll('input');

      // Convert NodeList to an array
      const inputsArray = Array.from(inputElements);

      // Check that all inputs are filled (trim() is used to remove spaces).
      checkSaveForm = inputsArray.every((input) => input.value.trim() !== '');
    }

    if (checkSaveForm) {
      let codes: Confirm = {};

      if (verification) {
        if (verification.methods.includes('phone')) {
          codes.sms_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('whatsapp')) {
          codes.whatsapp_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('email')) {
          codes.email_verification_code = Number(codeEmail);
        }
        if (verification.methods.includes('ga')) {
          codes.ga_verification_code = String(codeGA);
        }

        const verificationParams: VerificationConfirm = {
          confirm: {
            ...codes,
          },
          verification_id: verification.verification_id,
        };

        const responseConfirm = await dispatch(fetchVerificationsConfirm(verificationParams));

        if (!responseConfirm.payload === true) {
          if (verification) {
            if (verification.methods.includes('email')) {
              setVerifyInputError((prev) => (
                { ...prev, emailError: true }
              ));
            }
            if (verification.methods.includes('phone')) {
              setVerifyInputError((prev) => (
                { ...prev, phoneError: true }
              ));
            }
            if (verification.methods.includes('whatsapp')) {
              setVerifyInputError((prev) => (
                { ...prev, phoneError: true }
              ));
            }
            if (verification.methods.includes('ga')) {
              setVerifyInputError((prev) => (
                { ...prev, gaError: true }
              ));
            }
          }
        } else if (responseConfirm.payload === 'VERIFICATION_NOT_FOUND') {
          setAlertMessage('request expired', 'error');
          closeVerify();
          clearVerifyInputs();
        } else {
          closeVerify();
          clearVerifyInputs();
          handleTransfer();
        }
      }
    }
  };

  const withdrawalNoAvailable = useMemo(() => {
    if (!currentAsset || !quantity) return '';

    if (Number(quantity) > Number(getSpotWalletAccount(currentSubAccount?.id))) {
      return t('insufficient_balance');
    }

    if (currentAsset.asset_network.withdraw_min && (+quantity > 0) && (+quantity < +currentAsset.asset_network.withdraw_min)) {
      return `${t('min_withdrawal_amount')}: ${currentAsset.asset_network.withdraw_min} ${currentAsset.asset.symbol}`;
    }
    if (currentAsset.asset_network.withdraw_max && (+quantity > 0) && (+quantity > +currentAsset.asset_network.withdraw_max)) {
      return `${t('max_withdrawal_amount')} ${currentAsset.asset_network.withdraw_max} ${currentAsset.asset.symbol}`;
    }

    return '';
  }, [quantity, currentAsset]);

  const getAuthorizationWithdraw = () => {
    if (!currentAsset
        || statusWithdrawal === 'loading'
        || (Number(quantity) - Number(currentAsset?.asset_network.withdraw_fee)) < 0
        || Number(quantity) < Number(currentAsset?.asset_network.withdraw_min)
        || Number(quantity) > Number(getSpotWalletAccount(currentSubAccount?.id))) {
      return false;
    }
    return true;
  };

  const handleSubmitVerification: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    let checkSaveForm = false;
    const form = verifyFormRef.current;

    if (verification) {
      if (verification.methods.includes('email')) {
        setVerifyInputError((prev) => (
          { ...prev, emailError: codeEmail.trim() === '' }
        ));
      }
      if (verification.methods.includes('phone')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }
      if (verification.methods.includes('whatsapp')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }
      if (verification.methods.includes('ga')) {
        setVerifyInputError((prev) => (
          { ...prev, gaError: codeGA.trim() === '' }
        ));
      }
    }

    if (form) {
      const inputElements = form.querySelectorAll('input');

      // Convert NodeList to an array
      const inputsArray = Array.from(inputElements);

      // Check that all inputs are filled (trim() is used to remove spaces).
      checkSaveForm = inputsArray.every((input) => input.value.trim() !== '');
    }

    if (checkSaveForm) {
      let codes: Confirm = {};

      if (verification) {
        if (verification.methods.includes('phone')) {
          codes.sms_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('whatsapp')) {
          codes.whatsapp_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('email')) {
          codes.email_verification_code = Number(codeEmail);
        }
        if (verification.methods.includes('ga')) {
          codes.ga_verification_code = String(codeGA);
        }

        const verificationParams: VerificationConfirm = {
          confirm: {
            ...codes,
          },
          verification_id: verification.verification_id,
        };

        const responseConfirm = await dispatch(fetchVerificationsConfirm(verificationParams));

        if (responseConfirm.payload === true) {
          closeVerify();
          clearVerifyInputs();

          handleTransfer();
        } else if (responseConfirm.payload === 'VERIFICATION_NOT_FOUND') {
          setAlertMessage('request expired', 'error');
          closeVerify();
          clearVerifyInputs();
        } else if (verification) {
          if (verification.methods.includes('email')) {
            setVerifyInputError((prev) => (
              { ...prev, emailError: true }
            ));
          }
          if (verification.methods.includes('phone')) {
            setVerifyInputError((prev) => (
              { ...prev, phoneError: true }
            ));
          }
          if (verification.methods.includes('whatsapp')) {
            setVerifyInputError((prev) => (
              { ...prev, phoneError: true }
            ));
          }
          if (verification.methods.includes('ga')) {
            setVerifyInputError((prev) => (
              { ...prev, gaError: true }
            ));
          }
        }
      }
    }
  };

  const renderVerificationForm = (methods: string[]) => {
    const formElements: React.ReactNode[] = [];

    if (methods.includes('email')) {
      formElements.push(
        <div key="email-input" className="verification-input">
          <InputAction
            label={t('code from email')}
            placeholder={t('code')}
            type="number"
            actionTitle={t('send code')}
            onClick={handleFetchCodeEmail}
            actionActive
            error={verifyInputError.emailError}
            {...{
              value: codeEmail,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodeEmail(e.target.value),
            }}
          />
          <span className="verification-input-text">{t('confirmation code will be sent to')} {hideEmail(user?.email)}</span>
        </div>,
      );
    }

    if (methods.includes('phone')) {
      formElements.push(
        <div key="sms-input" className="verification-input">
          <InputAction
            label={t('sms code')}
            placeholder={t('code')}
            actionTitle={t('send code')}
            actionActive
            onClick={handleFetchCodeSms}
            type="number"
            error={verifyInputError.phoneError}
            {...{
              value: codePhone,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodePhone(e.target.value),
            }}
          />
        </div>,
      );
    }

    if (methods.includes('whatsapp')) {
      formElements.push(
        <div key="sms-input" className="verification-input">
          <InputAction
            label={t('whatsapp code')}
            placeholder={t('code')}
            actionTitle={t('send code')}
            actionActive
            onClick={handleFetchCodeSms}
            type="number"
            error={verifyInputError.phoneError}
            {...{
              value: codePhone,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodePhone(e.target.value),
            }}
          />
        </div>,
      );
    }

    if (methods.includes('ga')) {
      formElements.push(
        <div key="ga-input" className="verification-input">
          <InputForm title={t('google authenticator code')}>
            <InputCode focus={verification?.methods.every((method) => method === 'ga')} error={verifyInputError.gaError} onChange={setCodeGA} />
          </InputForm>
        </div>,
      );
    }

    formElements.push(
      <div key="modal-hint" className="verification-input">
        <Hint
          hintOpen
          position="bottom-start"
          text={t('verification issues')}
          hintText={<VerificationIssues onClick={handleSupportTelegram} />}
        />
      </div>,
    );

    formElements.push(
      <div key="verify-modal-action" className="verify-modal-action">
        <Button
          disabled={statusConfirm === EStatus.loading}
          loading={statusConfirm === EStatus.loading}
          onClick={() => {}}
          type="submit"
          background="green"
        >{t('confirm')}
        </Button>
        <Button disabled={statusConfirm === EStatus.loading} onClick={handleCloseVerify} background="gray">{t('cancel')}</Button>
      </div>,
    );

    return <form onSubmit={handleSubmitVerification} ref={verifyFormRef} className="verification-form-wrapper">{formElements}</form>;
  };

  useEffect(() => {
    const parsedParams = qs.parse(queryString, { ignoreQueryPrefix: true });
    const querySubAccountId = parsedParams?.sub_account_id;

    if (querySubAccountId && subAccounts) {
      const parsedSubAccount = subAccounts.find((subAccount) => subAccount.id === Number(querySubAccountId));

      if (parsedSubAccount) {
        setSubAccoundId(parsedSubAccount.id);
        setCurrentSubAccount(parsedSubAccount);
      }
      return;
    }

    if (subAccounts) {
      const [firstSubAccount] = subAccounts.slice().sort(sortSubAccountsByFavorite);

      if (firstSubAccount) {
        setSubAccoundId(firstSubAccount.id);
        setCurrentSubAccount(firstSubAccount);
      }
    }
  }, [subAccounts]);

  useEffect(() => {
    if (currentAsset && currentSubAccount && subAccountAssets) {
      const assetsBalance = subAccountAssets.find((item) => item.asset.symbol === currentAsset.asset.symbol && item.sub_account_id === currentSubAccount?.id && item.asset_type === ETradingType.spot);
      const balanceSpot = String(assetsBalance?.quantity || 0);
      const withdrawMultiplicity = currentAsset.asset_network.withdraw_multiplicity;

      setSubAccoundSum(toFixedDecimalPrecision(balanceSpot, withdrawMultiplicity));
    }
  }, [currentAsset, currentSubAccount, subAccountAssets, currentNetwork]);

  useEffect(() => {
    if (!currentWallet || !currentSubAccount) return;

    const params: WalletAttributesParams = {
      wallet_id: currentWallet.wallet_id,
      exchange_id: currentSubAccount.exchange_id,
    };

    dispatch(fetchWalletAttributes(params));
  }, [currentWallet, currentSubAccount]);

  useEffect(() => {
    const parsedParams = qs.parse(queryString, { ignoreQueryPrefix: true });
    const queryAssetSymbol = parsedParams?.asset_symbol;

    const currentAsset = walletAttributes?.assets?.find((item) => item.asset.symbol === queryAssetSymbol);

    if (queryAssetSymbol && !exists) {
      setCurrentWallet(null);
      dispatch(setCurrentAssetOutput(null));
      dispatch(setCurrentNetworkOutput(null));
    }

    if (currentAsset) {
      handleSetCurrentAsset(currentAsset);
      dispatch(setCurrentAssetOutput(currentAsset));
    }
  }, [walletAttributes]);

  useEffect(() => {
    const parsedParams = qs.parse(queryString, { ignoreQueryPrefix: true });

    const withdrawalSubAccountId = parsedParams?.withdrawal_sub_account_id;

    if (withdrawalSubAccountId) setSubAccoundId(Number(withdrawalSubAccountId));

    if (subAccounts) {
      const currentSubAccount = subAccounts.find((subAccount) => subAccount.id === Number(withdrawalSubAccountId));

      if (currentSubAccount) setCurrentSubAccount(currentSubAccount);
    }

    return () => {
      dispatch(clearAssetsSlice());
    };
  }, []);

  useLayoutEffect(() => {
    getWhiteWallets();
    dispatch(fetchSubAccountsAssets({}));

    return () => { dispatch(clearWallets()); };
  }, []);

  useLayoutEffect(() => {
    const parsedParams = qs.parse(queryString, { ignoreQueryPrefix: true });
    const queryAssetSymbol = parsedParams?.asset_symbol;

    if (typeof queryAssetSymbol === 'string') setCurrentAssetSymbol(`${t('for_currency')} - ${queryAssetSymbol}`);
  }, []);

  useEffect(() => {
    if (verification) {
      const onlyGa = verification.methods.every((method) => method === 'ga');
      const onlyEmail = verification.methods.every((method) => method === 'email');
      const hasGaAndEmail = verification.methods.length === 2 && (verification.methods.includes('ga') && verification.methods.includes('email'));
      const hasGaAndPhone = verification.methods.length === 2 && (verification.methods.includes('ga') && verification.methods.includes('phone'));
      const hasGaAndWhatsapp = verification.methods.length === 2 && (verification.methods.includes('ga') && verification.methods.includes('whatsapp'));
      const hasGaAndWhatsappAndEmail = verification.methods.length === 3 && (verification.methods.includes('ga') && verification.methods.includes('whatsapp') && verification.methods.includes('email'));
      const hasGaAndPhoneAndEmail = verification.methods.length === 3 && (verification.methods.includes('ga') && verification.methods.includes('phone') && verification.methods.includes('email'));

      if (onlyGa) {
        if (codeGA.length === 6) {
          handleAutoSubmit();
        }
      }

      if (onlyEmail) {
        if (codeEmail.length === 5) {
          handleAutoSubmit();
        }
      }

      if (hasGaAndEmail) {
        if (codeEmail.length === 5 && codeGA.length === 6) {
          handleAutoSubmit();
        }
      }

      if (hasGaAndPhone) {
        if (codePhone.length === 5 && codeGA.length === 6) {
          handleAutoSubmit();
        }
      }

      if (hasGaAndWhatsapp) {
        if (codePhone.length === 4 && codeGA.length === 6) {
          handleAutoSubmit();
        }
      }

      if (hasGaAndWhatsappAndEmail) {
        if (codePhone.length === 4 && codeGA.length === 6 && codeEmail.length === 5) {
          handleAutoSubmit();
        }
      }

      if (hasGaAndPhoneAndEmail) {
        if (codePhone.length === 5 && codeGA.length === 6 && codeEmail.length === 5) {
          handleAutoSubmit();
        }
      }
    }
  }, [codeGA, codeEmail, codePhone, verification]);

  return (
    <div className="withdrawal-wrapper">
      <h4 className="title">{t('withdrawal')}</h4>

      <div className="user-accounts">
        <InputForm title={`${t('from_account')}:`}>
          {subAccounts && currentSubAccount ? (
            <Select
              isActiveSearch={false}
              isSelectValue={(
                <div className="sub-account-select-item">
                  <img src={exchanges ? getSubAccountImage(currentSubAccount.exchange_id, exchanges) : DefaultIconSvg} alt="exchange" />
                  <BalanceType type={typeSubAccountBack(currentSubAccount.exchange_id)} />
                  {currentSubAccount.user_name}
                  <span className="item-spot-wallet">{!balanceVisible ? `${decimalizeQuantity(getSpotWalletAccount(currentSubAccount.id), currentAsset?.asset.symbol)} ${currentAsset?.asset.symbol || ''}` : HIDDEN_BALANCES}</span>
                </div>
              )}
            >
              {subAccounts.slice().sort(sortSubAccountsByFavorite).map((account) => (
                <div
                  key={account.id}
                  className="sub-account-select-item"
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => handleSetSubAccount(account)}
                  onClick={() => handleSetSubAccount(account)}
                >
                  <img src={exchanges ? getSubAccountImage(account.exchange_id, exchanges) : DefaultIconSvg} alt="exchange" />
                  <BalanceType type={typeSubAccountBack(account.exchange_id)} />
                  {account.user_name}
                  <span className="item-spot-wallet">{!balanceVisible ? decimalizeQuantity(getSpotWalletAccount(account.id), currentAsset?.asset.symbol) : HIDDEN_BALANCES}</span>
                </div>
              ))}
            </Select>
          ) : <SelectSkeleton />}
        </InputForm>
      </div>

      <div className="user-white-wallets">
        <div className="input-form">
          <div className="input-form-content">
            <InputForm title={t('select_wallet')} isAction actionTitle={t('add_new_wallet')} action={handleAddNewWallet}>
              {status === EStatus.success ? (
                <Select
                  disabledSelect={!wallets || wallets.length === 0}
                  isActiveSearch={false}
                  isSelectValue={(
                    <span
                      className="key-item"
                    >
                      {
                        currentWallet
                          ? (
                            <>
                              {currentWallet.is_sub_account && <img style={{ width: '20px' }} src={Logotype} alt="logo" />}
                              {currentWallet.user_name }
                            </>
                          ) : `${t('no_available_wallets')} ${currentAssetSymbol}`
                      }

                    </span>
                  )}
                >
                  { wallets.map((wallet) => (
                    <div
                      className="key-item"
                      key={wallet.wallet_id}
                      role="button"
                      tabIndex={0}
                      onClick={() => handleCurrentWallet(wallet)}
                      onKeyDown={() => handleCurrentWallet(wallet)}
                    >
                      {wallet.is_sub_account && <img style={{ width: '20px' }} src={Logotype} alt="logo" />}
                      {wallet.user_name}
                      <p className="key-item-address">{wallet.address}</p>
                    </div>
                  )) }
                </Select>
              ) : <SelectSkeleton />}
            </InputForm>
          </div>
        </div>
      </div>

      <AdviceParagraph isHidden={Boolean(currentWallet)} big warning>
        <button onClick={handleAddNewWallet}>{t('add_wallet')}</button>
        {t('for_currency_network')}
      </AdviceParagraph>

      <div className={join('user-white-wallets-hint', Boolean(currentWallet) && 'hint-active')}>
        <AuthenticatorIconSvg />
        {t('withdrawals_available_on')}
        <button onClick={handleAddNewWallet}>{t('_white_wallets')}</button>
      </div>

      <div className="instruments">
        <InputForm title={t('currency')}>
          { walletAttributes && currentAsset ? (
            <Select
              isActiveSearch={walletAttributes.assets.length > 5}
              searchValue={searchAssetSymbol}
              isSearch={setSearchAssetSymbol}
              isSelectValue={(
                <div className="instrument">
                  <img src={currentAsset.asset.image} alt={currentAsset.asset.symbol} />
                  <span className="short-name">{currentAsset.asset.symbol}</span>
                  <span className="full-name">{currentAsset.asset.name}</span>
                </div>
              )}
            >
              {
                walletAttributes.assets.filter((assetData) => assetData.asset.symbol.toLowerCase().includes(searchAssetSymbol.toLowerCase())).map((asset) => (
                  <div
                    key={asset.asset.id}
                    className="instrument"
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => handleSetCurrentAsset(asset)}
                    onClick={() => handleSetCurrentAsset(asset)}
                  >
                    <img src={asset.asset.image} alt={asset.asset.symbol} />
                    <span className="short-name">{asset.asset.symbol}</span>
                    <span className="full-name">{asset.asset.name}</span>
                  </div>
                ))
              }
            </Select>
          ) : <SelectSkeleton /> }
        </InputForm>

        <InputForm title={t('network')}>
          { walletAttributes && currentNetwork ? (
            <Select
              isSelectValue={(
                <div
                  className="network"
                >
                  <span className="short-name">{currentNetwork.symbol}</span>
                  <span className="full-name">{currentNetwork.name}</span>
                </div>
              )}
              isActiveSearch={false}
            >
              {
                [walletAttributes.network].map((network) => (
                  <div
                    key={network.id}
                    className="network"
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => handleSetCurrentNetwork(network)}
                    onClick={() => handleSetCurrentNetwork(network)}
                  >
                    <span className="short-name">{network.symbol}</span>
                    <span className="full-name">{network.name}</span>
                  </div>
                ))
              }
            </Select>
          ) : <SelectSkeleton /> }
        </InputForm>
      </div>

      <div className="withdrawal-sum">
        <InputForm title={`${t('amount')}:`}>
          <InputText
            value={quantity}
            placeholder={`${t('minimum_amount')}: ${currentAsset?.asset_network.withdraw_min || 0} ${currentAsset?.asset?.symbol || ''}`}
            onChange={handleSetQuantity}
            inputType={!balanceVisible ? 'text' : 'password'}
          />

          {withdrawalNoAvailable && <AdviceParagraph className="withdrawal-hint">{withdrawalNoAvailable}</AdviceParagraph>}

          <div className="divisor-wrapper">
            {
              [25, 50, 75, 100].map((value) => (
                <Divisor
                  key={value}
                  value={value}
                  active={divisorSum === value.toFixed(1)}
                  onClick={() => handleDivisorSum(value)}
                />
              ))
            }
          </div>
        </InputForm>
      </div>

      <div className="withdrawal-result">
        <div className="commissions">
          <p className="sum-to-receive">
            {t('you_will_receive')}
            {currentAsset && (
              <>
                <span>
                  { quantity ? !balanceVisible ? toFixed(minusValues(quantity, currentAsset.asset_network.withdraw_fee), currentAsset.asset_network.withdraw_multiplicity) : HIDDEN_BALANCES : 0}
                </span>
                <span>{currentAsset?.asset.symbol}</span>
              </>
            )}
          </p>

          <p className="network-comission">
            {t('network_fee')}
            <span>{currentAsset?.asset_network.withdraw_fee} {currentAsset?.asset.symbol}</span>
          </p>
        </div>

        <Button
          loading={verifyStatusWithdrawal || statusWithdrawal === EStatus.loading}
          disabled={!getAuthorizationWithdraw()}
          onClick={handleVerifyTransfer}
          background={getAuthorizationWithdraw() ? 'green' : 'black'}
        >
          {t('execute_transfer')}
        </Button>
      </div>

      <Modal
        title={t('confirm action')}
        radius={16}
        size="sm"
        opened={openedVerify}
        onClose={handleCloseVerify}
        closeOnClickOutside={false}
        closeOnEscape={false}
        className="modal-custom-overflow"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <div>
          {verification && renderVerificationForm(verification.methods)}
        </div>
      </Modal>
    </div>
  );
});

export default Withdrawals;
