import { MantineProvider, Modal } from '@mantine/core';
import { I18nextProvider } from 'react-i18next';
import { useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { useAuth } from './shared/libs/hooks/use-auth';
import { ROUTE_PATH } from './shared/constants/route.path';
import { ELogOutDetail } from './shared/types/global-types';
import { useStorageListener } from './shared/libs/hooks/use-storage-listener';

import i18n from './app/locales/i18n';
import Routers from './app/routers';
import useWebWorker from './shared/libs/hooks/use-web-worker';
import useFetchUserData from './shared/libs/hooks/use-fetch-user-data';
import useHandlePartnerId from './shared/libs/hooks/use-handle-partner-id';
import LogoutModalContent from './entities/logout-modal-content/logout-modal-content';
import 'src/app/styles/app.scss';

const App = () => {
  const { isAuth, logOut } = useAuth();
  const { data, error, clearErrorMessage } = useWebWorker(ROUTE_PATH.auth.isAlive, 30000);
  const [openedLogOut, { open, close }] = useDisclosure(false);
  useStorageListener();
  useFetchUserData(isAuth);
  useHandlePartnerId(isAuth);

  const handleLogOut = () => {
    close();
    logOut();
  };

  useEffect(() => {
    if (!error) return;

    if (error === ELogOutDetail.ANOTHER_DEVICE_LOGIN) {
      open();
      clearErrorMessage();
    } else if (error === ELogOutDetail.INVALID_TOKEN) {
      logOut();
      clearErrorMessage();
    }
  }, [data, error]);

  return (
    <I18nextProvider i18n={i18n}>
      <MantineProvider>
        <Routers />
        <Modal
          style={{ overflow: 'unset' }}
          radius={16}
          size="sm"
          opened={openedLogOut}
          onClose={close}
          closeOnClickOutside={false}
          closeOnEscape={false}
          withCloseButton={false}
          className="modal-custom-overflow modal-border white-filter"
          overlayProps={{
            backgroundOpacity: 0.55,
            blur: 3,
          }}
        >
          <LogoutModalContent logOut={handleLogOut} />
        </Modal>
      </MantineProvider>
    </I18nextProvider>
  );
};

export default App;
