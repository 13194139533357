import moment from 'moment';
import classNames from 'classnames';
import { Typewriter } from 'react-simple-typewriter';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LoadingOverlay } from '@mantine/core';
import { useEffect, useMemo } from 'react';
import { EStatus } from 'src/shared/types/global-types';
import { useAppDispatch } from 'src/app/store/store';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import Empty from 'src/entities/empty/empty';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import BalanceType from 'src/entities/balance-type/balance-type';
import useMobile from 'src/shared/libs/hooks/use-mobile';
import { referralsBonusesTableColumn } from '../../constants';
import { fetchReferralsBonuses, ReferralsBonusesParams } from '../../model/thunk';
import { selectReferralsBonusesFilters, selectReferralsBonuses, selectReferralStatus } from '../../model/selectors';
import styles from './referrals-table.module.scss';
import ReferralsTableCard from '../referrals-table-card/referrals-table-card';

const ReferralsTable = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { getExchangeImageById } = useExchange();
  const isMobile = useMobile();

  const filters = useSelector(selectReferralsBonusesFilters);
  const status = useSelector(selectReferralStatus);
  const balanceVisible = useSelector(selectHiddenBalance);
  const referralsBonuses = useSelector(selectReferralsBonuses);

  const isSuccess = useMemo(() => Boolean(status === EStatus.success), [status]);
  const isLoading = useMemo(() => Boolean(status === EStatus.loading), [status]);
  const isRejected = useMemo(() => Boolean(status === EStatus.rejected), [status]);

  useEffect(() => {
    const params:ReferralsBonusesParams = {
      page: filters.page,
      limit: 20,
      from_date: filters.dateRange[0],
      to_date: filters.dateRange[1],
    };

    if (filters.exchange) params.exchange_id = filters.exchange.id;
    if (filters.tradingType) params.trading_type = filters.tradingType;
    if (filters.userId) params.user_id = filters.userId;

    dispatch(fetchReferralsBonuses(params));
  }, [filters]);

  return (
    <div className={classNames(styles.tableContainer, { [styles.adaptive]: isMobile })}>
      <div className={classNames(styles.tableWrapper)}>
        {!isMobile && (
          <table className={styles.referralsTable}>
            <thead className={styles.thead}>
              <tr>
                {referralsBonusesTableColumn?.map((column) => (
                  <th key={column.key} className={classNames(styles.th, { [styles.right]: column.type === 'numberic' })}>
                    <div className={classNames(styles.thWrapper)}>
                      <span className={styles.thWrapperBody}>
                        {t(String(column.label))}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className={styles.tbody}>
              {referralsBonuses?.items.map((referralBonus, index) => (
                <tr key={referralBonus.id}>
                  {
                    referralsBonusesTableColumn?.map((column) => (
                      <td key={column.key} className={classNames(styles.td)}>
                        {column.key === 'user_id' && (
                          <div className={styles.tdWrapper}>
                            {referralBonus.user_id}
                          </div>
                        )}
                        {column.key === 'payment_date' && (
                          <div className={styles.tdWrapper}>
                            {moment(referralBonus.created_at).format('DD.MM.YYYY')}
                          </div>
                        )}
                        {column.key === 'trading_date' && (
                          <div className={styles.tdWrapper}>
                            {moment(referralBonus.paid_at).format('DD.MM.YYYY')}
                          </div>
                        )}
                        {column.key === 'exchange' && (
                          <div className={styles.tdWrapper}>
                            <img src={getExchangeImageById(referralBonus.exchange_id)} alt="exchange" />
                          </div>
                        )}
                        {column.key === 'trading_type' && (
                          <div className={styles.tdWrapper}>
                            <BalanceType type={referralBonus.trading_type} />
                          </div>
                        )}
                        {column.key === 'cummulative_quantity' && (
                          <div className={styles.tdWrapper}>
                            {
                              !balanceVisible ? (
                                <>
                                  {referralBonus.cummulative_quantity} <span className={styles.symbol}>$</span>
                                </>
                              ) : HIDDEN_BALANCES
                            }

                          </div>
                        )}
                        {column.key === 'commission' && (
                          <div className={styles.tdWrapper}>
                            {
                              !balanceVisible ? (
                                <>
                                  {referralBonus.commission} <span className={styles.symbol}>$</span>
                                </>
                              ) : HIDDEN_BALANCES
                            }
                          </div>
                        )}
                        {column.key === 'bonus' && (
                          <div className={styles.tdWrapper}>
                            {
                              !balanceVisible ? (
                                <>
                                  {referralBonus.bonus} <span className={styles.symbol}>$</span>
                                </>
                              ) : HIDDEN_BALANCES
                            }
                          </div>
                        )}
                      </td>
                    ))
                  }
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <ReferralsTableCard data={referralsBonuses?.items} visible={isMobile} />

        { !isMobile && (
          <>
            {isSuccess && referralsBonuses?.items.length === 0 && (
              <Empty maxWidthText="200px">
                {t('no-history-for-selected-period')}
              </Empty>
            )}
            {isLoading && (
              <div className={styles.emptyTable}>
                <LoadingOverlay
                  className="loader"
                  style={{ height: '100%' }}
                  visible
                  zIndex={1000}
                  overlayProps={{ radius: 'sm', blur: 2 }}
                  loaderProps={{ color: '#00C37C', type: 'dots' }}
                />
              </div>
            )}
            {isRejected && (
              <div className={styles.emptyTable}>
                <Empty horizontal error>
                  <Typewriter
                    words={[t('internal_error_message')]}
                    loop={1}
                    // cursor
                    cursorStyle="_"
                    typeSpeed={20}
                    delaySpeed={1500}
                    deleteSpeed={20}
                  />
                </Empty>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ReferralsTable;
