import { store } from 'src/app/store/store';
import { addSubsciptionQueue, removeSubsciptionQueue } from 'src/app/store/slices/subscriptions/slice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { socket } from 'src/shared/api/websocket.instance';

import { devConsoleLog } from 'src/shared/libs/helpers/helper.lib';
import { ETradingType } from 'src/shared/types/global-types';
import {
  EInputEventType, EOutputEventType, ISubscribeEvent, IUnsubscribeEvent,
} from '../../../shared/types/ws-interfaces';
import {
  Order,
  OrderToClosed, Position, PositionToClosed, PositionToHidden,
} from './types';

export type PositionReduceParams = {
  position_id: string
  reduce_percent: number
  sub_account_id: number
  trading_type: ETradingType
  exchange_id: number
}

export type HiddenPositionsParams = {
  positions: PositionToHidden[]
  is_visible: boolean
}

export const subscribePositions = () => {
  const { dispatch } = store;

  const intervalId = setInterval(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      devConsoleLog('Подписка на Positions');

      const subscribeMessage: ISubscribeEvent = {
        event: EInputEventType.SUBSCRIBE,
        metadata: {
          event_type: EOutputEventType.POSITIONS,
        },
      };

      socket.send(JSON.stringify(subscribeMessage));
      dispatch(addSubsciptionQueue(subscribeMessage));
      clearInterval(intervalId);
    }
  }, 500);
};

export const unsubscribePositions = () => {
  const { dispatch } = store;

  if (socket && socket.readyState === WebSocket.OPEN) {
    devConsoleLog('Отписка от Positions');

    const unsubscribeMessage: IUnsubscribeEvent = {
      event: EInputEventType.UNSUBSCRIBE,
      metadata: {
        event_type: EOutputEventType.POSITIONS,
      },
    };

    socket.send(JSON.stringify(unsubscribeMessage));
    dispatch(removeSubsciptionQueue(unsubscribeMessage.metadata.event_type));
  }
};

export const subscribeOrders = () => {
  const { dispatch } = store;

  const intervalId = setInterval(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      devConsoleLog('Подписка на Orders');

      const subscribeMessage: ISubscribeEvent = {
        event: EInputEventType.SUBSCRIBE,
        metadata: {
          event_type: EOutputEventType.ORDERS,
        },
      };

      socket.send(JSON.stringify(subscribeMessage));
      dispatch(addSubsciptionQueue(subscribeMessage));
      clearInterval(intervalId);
    }
  }, 500);
};

export const unsubscribeOrders = () => {
  const { dispatch } = store;

  if (socket && socket.readyState === WebSocket.OPEN) {
    devConsoleLog('Отписка от Orders');

    const unsubscribeMessage: IUnsubscribeEvent = {
      event: EInputEventType.UNSUBSCRIBE,
      metadata: {
        event_type: EOutputEventType.ORDERS,
      },
    };

    socket.send(JSON.stringify(unsubscribeMessage));
    dispatch(removeSubsciptionQueue(unsubscribeMessage.metadata.event_type));
  }
};

export const fetchPositions = createAsyncThunk(
  'trading/fetchPositions',
  async (isVisible: boolean, { rejectWithValue }) => {
    try {
      const response = await $api.get<Position[]>(ROUTE_PATH.positions.get, {
        params: {
          is_visible: isVisible,
        },
      });
      const { data } = response;

      if (data === undefined) throw response;

      subscribePositions();

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchPositionReduce = createAsyncThunk(
  'trading/fetchPositionReduce',
  async (positionReduce: PositionReduceParams, { rejectWithValue }) => {
    try {
      const response = await $api.post(ROUTE_PATH.positions.reduce, positionReduce);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchPositionsClose = createAsyncThunk(
  'trading/fetchPositionsClose',
  async (positionToClosed: PositionToClosed[], { rejectWithValue }) => {
    try {
      const response = await $api.post(ROUTE_PATH.positions.close, { positions: positionToClosed });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchPositionsCloseAll = createAsyncThunk(
  'trading/fetchPositionsCloseAll',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.post(ROUTE_PATH.positions.closeAll);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchOrders = createAsyncThunk(
  'trading/fetchOrders',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get<Order[]>(ROUTE_PATH.orders.get);

      const { data } = response;

      if (data === undefined) throw response;

      subscribeOrders();

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchOrderCancel = createAsyncThunk(
  'trading/fetchOrderCancel',
  async (orderToClosed: OrderToClosed[], { rejectWithValue }) => {
    try {
      const response = await $api.post(ROUTE_PATH.orders.close, { orders: orderToClosed });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchOrderCancelAll = createAsyncThunk(
  'trading/fetchOrderCancelAll',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.post(ROUTE_PATH.orders.closeAll);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchPositionsPnl = createAsyncThunk(
  'trading/fetchPositionsPnl',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get(ROUTE_PATH.positions.pnl);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data);
      return rejectWithValue(error);
    }
  },
);

export const fetchHiddenPositions = createAsyncThunk(
  'trading/fetchHiddenPositions',
  async (params: HiddenPositionsParams, { rejectWithValue }) => {
    try {
      const response = await $api.put(ROUTE_PATH.positions.visability, params);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);
