import moment from 'moment';
import {
  useCallback, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Exchange } from 'src/app/store/slices/exchanges/types';
import { debounce } from 'src/shared/libs/helpers/helper.lib';
import { EThemeMode } from 'src/app/store/slices/user/types';
import { selectTheme } from 'src/app/store/slices/user/selectors';
import { BalanceType } from 'src/entities/balance-type';
import { TRADING_TYPES } from 'src/shared/constants/constants';
import { useAppDispatch } from 'src/app/store/store';
import { Nullable, TradingType } from 'src/shared/types/global-types';
import Select from 'src/shared/ui/select/select';
import Button from 'src/shared/ui/button/button/button';
import GlassIcon from 'src/shared/assets/icons/glass-icon/glass-icon';
import useMobile from 'src/shared/libs/hooks/use-mobile';
import InputText from 'src/shared/ui/input/input-text/input-text';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import DataPickerRange from 'src/shared/ui/date/data-range-picker';
import { setReferralsFilters } from '../../model/slice';
import { selectReferralsBonusesFilters } from '../../model/selectors';
import { ALL_EXCHANGES_ITEM, ALL_TRADING_TYPE_ITEM } from '../../constants';
import styles from './filter.module.scss';

const Filters = () => {
  const isMobile = useMobile();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { availableExchanges: exchanges } = useExchange();

  const theme = useSelector(selectTheme);
  const filters = useSelector(selectReferralsBonusesFilters);
  const isDark = theme === EThemeMode.DARK;

  const [dateRange, setDateRange] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const debouncedFetch = useCallback(
    debounce((value: string) => {
      const params = {
        ...filters,
        userId: Number(value),
      };

      dispatch(setReferralsFilters(params));
    }, 500) as any,
    [filters],
  );

  const handleClearDateRange = () => {
    setDateRange([]);
    dispatch(setReferralsFilters({
      ...filters,
      dateRange: [],
    }));
  };

  const handleSetFilterTradingType = (type: Nullable<TradingType>) => {
    if (filters.tradingType === type) return;

    dispatch(setReferralsFilters({
      ...filters,
      tradingType: type,
    }));
  };

  const handleResetFilters = () => {
    if (!searchValue.length && !dateRange.length && !filters.exchange && !filters.tradingType) return;

    setSearchValue('');
    setDateRange([]);

    dispatch(setReferralsFilters({
      tradingType: null,
      exchange: null,
      dateRange: [],
      userId: null,
      page: 1,
      limit: 20,
    }));
  };

  const handleSetFilterExchange = (exchange: Exchange) => {
    if (filters.exchange?.id === exchange.id) return;
    if (!filters.exchange && exchange.id === 0) return;

    dispatch(setReferralsFilters({
      ...filters,
      exchange: exchange.id === 0 ? null : exchange,
    }));
  };

  const handleSearchByUserID = (value: number) => {
    setSearchValue(String(value));

    debouncedFetch.execute(String(value));
  };

  useEffect(() => {
    if (!dateRange.length) return;

    if (dateRange[1] !== null) {
      dispatch(setReferralsFilters({
        ...filters,
        dateRange: dateRange.map((date, index) => (index === 0
          ? moment(date).startOf('day').toISOString()
          : moment(date).endOf('day').toISOString())),
      }));
    }
  }, [dateRange]);

  return (
    <div className={styles.filters}>
      <DataPickerRange
        isClearDateRange={handleClearDateRange}
        whiteMode={theme === 'light'}
        removeData={() => {}}
        setPage={() => {}}
        setDateRange={setDateRange}
        dateRange={dateRange}
        className={styles.dataPickerCustom}
      />

      <InputForm className={styles.inputFormWrapper} minWidth="150px" maxWidth={isMobile ? 'auto' : '200px'} title={t('exchange')}>
        <Select
          classNameSelect="filter-select"
          isActiveSearch={false}
          isSelectValue={
            filters.exchange ? (
              <div className={styles.isSelectValue}>
                {filters.exchange.image && <img src={filters.exchange.image} alt="exchange" />}
                { t(filters.exchange.name)}
              </div>
            ) : <span className={styles.isSelectValue}>{t('all')}</span>
          }

        >
          {exchanges ? (
            [ALL_EXCHANGES_ITEM, ...exchanges].map((exchange) => (
              <div
                key={exchange.name}
                className={styles.isSelectValue}
                role="button"
                tabIndex={0}
                onClick={() => handleSetFilterExchange(exchange)}
                onKeyDown={() => {}}
              >
                {exchange.image && <img src={exchange.image} alt="exchange" />}
                {t(exchange.name)}
              </div>
            ))
          ) : (
            ['no data'].map((empty) => (<div key={empty} className={styles.empty}>{t(empty)}</div>))
          )}
        </Select>
      </InputForm>

      <InputForm className={styles.inputFormWrapper} minWidth="150px" maxWidth={isMobile ? 'auto' : '150px'} title={t('account_type')}>
        <Select
          classNameSelect="filter-select"
          isActiveSearch={false}
          isSelectValue={
            filters.tradingType
              ? (
                <div className={styles.isSelectValue}>
                  <BalanceType type={filters.tradingType} />
                  {t(filters.tradingType)}
                </div>
              ) : <span className={styles.isSelectValue}>{t('all')}</span>
          }
        >
          {
            [ALL_TRADING_TYPE_ITEM, ...TRADING_TYPES].map(({ type, name }) => (
              <div
                className={styles.isSelectValue}
                key={type}
                role="button"
                tabIndex={0}
                onClick={() => handleSetFilterTradingType(type)}
                onKeyDown={() => {}}
              >
                {type && <BalanceType type={type} />}
                {type ? t(type) : t(name)}
              </div>
            ))
          }
        </Select>
      </InputForm>

      <InputForm className={styles.inputFormWrapper} minWidth="150px" maxWidth={isMobile ? 'auto' : '200px'} title="User ID">
        <InputText
          inputType="number"
          className={styles.searchInput}
          value={searchValue}
          placeholder={t('search')}
          onChange={handleSearchByUserID}
        ><GlassIcon />
        </InputText>
      </InputForm>

      <Button className={styles.resetBtn} onClick={handleResetFilters} minWidth="200px" maxWidth={isMobile ? 'auto' : '200px'} background={isDark ? 'white' : 'black'}>{t('reset filters')}</Button>
    </div>
  );
};

export default Filters;
