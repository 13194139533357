import classNames from 'classnames';
// import HamsterIconSvg from 'src/shared/assets/images/trading/wait-positions-new-year.svg';
import HamsterIconSvg from 'src/shared/assets/images/trading/wait-positions.svg';
import HamsterErrorIconSvg from 'src/shared/assets/images/hamster-error.svg';
import HamsterErrorHoverIconSvg from 'src/shared/assets/images/hamster-error-hover.svg';
import styles from './empty.module.scss';

interface IEmptyProps {
  children: React.ReactNode
  error?: boolean
  horizontal?: boolean
  className?: string
  maxWidthText?: string
}
const Empty = ({
  children, error, horizontal, className, maxWidthText,
}: IEmptyProps) => (
  <div className={classNames(
    className,
    styles.empty,
    { [styles.error]: error },
    { [styles.horizontal]: horizontal },
  )}
  >
    <p style={{ width: maxWidthText }}>
      {children}
    </p>
    {error ? (
      <div className={styles.hamsterWrapper}>
        <div className={styles.see}><img src={HamsterErrorIconSvg} alt="hamster" /></div>
        <div className={styles.notSee}><img src={HamsterErrorHoverIconSvg} alt="hamster" /></div>
      </div>
    ) : <img src={HamsterIconSvg} alt="hamster" />}
  </div>
);

export default Empty;
