import { useEffect, useRef, useState } from 'react';
import { DetailError, Nullable } from 'src/shared/types/global-types';
import { BASE_URL } from 'src/shared/constants/constants';
import { socket } from 'src/shared/api/websocket.instance';
import { getTokensFromLocalStorage } from '../helpers/save-tokens';
import { useAuth } from './use-auth';

const useWebWorker = (url: string, interval: number) => {
  const { isAuth } = useAuth();
  const workerRef = useRef<Nullable<Worker>>(null);
  const [data, setData] = useState<null | boolean>(null);
  const [error, setError] = useState<Nullable<string>>(null);

  const fetchData = async () => {
    const token = getTokensFromLocalStorage()?.access_token;

    if (!isAuth || !token) {
      setError('Unauthorized or missing token');
      setData(null);
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Пытаемся парсить JSON и проверяем тип результата
      const result: DetailError | boolean = await response.json();

      if (typeof result === 'boolean') {
        setData(result);
      } else if ('detail' in result) {
        throw new Error(result.detail || 'Unknown error from API');
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (err: any) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
      stopWorker();
    }
  };

  const startWorker = () => {
    if (workerRef.current) {
      console.debug('%c♻ Worker started', 'background: #1CB47D; padding: 5px 10px; border-radius: 8px; font: 10px Arial; color: white;');
    }
  };

  const stopWorker = () => {
    if (workerRef.current) {
      workerRef.current.postMessage('stop');
      workerRef.current.terminate();
      workerRef.current = null;
      socket?.close();
    }
  };

  const clearErrorMessage = () => {
    setError(null);
    setData(null);
    stopWorker();
  };

  useEffect(() => {
    if (!isAuth) return () => { };

    const worker = new Worker(new URL('../../config/worker.ts', import.meta.url));
    workerRef.current = worker;

    startWorker();

    worker.postMessage({ interval });

    worker.onmessage = (event) => {
      if (event.data === 'tick') {
        fetchData();
      }
    };

    worker.onerror = (err) => {
      setError(`Worker error: ${err.message}`);
      stopWorker();
    };

    return () => {
      stopWorker();
    };
  }, [url, isAuth, interval]);

  return { data, error, clearErrorMessage };
};

export default useWebWorker;
