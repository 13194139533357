import {
  memo, useState, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { useAppDispatch } from 'src/app/store/store';
import { join } from 'src/shared/libs/helpers/helper.lib';
import { selectAlert } from 'src/app/store/slices/alert/selectors';
import { clearCalculatorDetailMessage } from 'src/pages/leverages/model/slice';
import { selectLeveragesCalculationQueue } from 'src/pages/leverages/model/selectors';

import Alerts from 'src/shared/ui/alert/alerts/alerts';
import useAlert from 'src/shared/libs/hooks/use-alert';
import AudioAlert from 'src/entities/audio-alert/audio-alert';
import AsideDiary from 'src/widgets/aside-diary/aside-diary';
import HeaderDiary from 'src/widgets/header-diary/header-diary';
import Notifications from 'src/widgets/notifications/notifications';
import DisconnectedWsModalContent from 'src/entities/disconnected-ws-modal-content/disconnected-ws-modal-content';

import ButtonClose from 'src/shared/ui/button/button-close/button-close';
import PortalModal from 'src/shared/ui/modal/portal-modal/portal-modal';
import styles from './diary-layout.module.scss';

interface ILayoutProps {
  page?: string;
  children?: React.ReactNode;
}

const DiaryLayout = memo(({ children, page }: ILayoutProps) => {
  const dispatch = useAppDispatch();
  const { isOpen, setAlertMessage } = useAlert();

  const [shownNotifications, setShownNotifications] = useState(false);
  const [openedWebSocketDisconnected, { open: openWebSocketDisconnected, close: closeWebSocketDisconnected }] = useDisclosure(false);

  const { disconnectedWebSocket } = useSelector(selectAlert);
  const leveregesCalculatorDetail = useSelector(selectLeveragesCalculationQueue);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (leveregesCalculatorDetail) {
      const { detail, type } = leveregesCalculatorDetail;

      if (detail) {
        setAlertMessage(detail, type, true);

        timeout = setTimeout(() => {
          dispatch(clearCalculatorDetailMessage());
          clearTimeout(timeout);
        }, 3500);
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [leveregesCalculatorDetail]);

  useEffect(() => {
    if (disconnectedWebSocket) openWebSocketDisconnected();
  }, [disconnectedWebSocket]);

  return (
    <div className={join('page', page)}>
      { shownNotifications && <Notifications setAreShownNotifications={setShownNotifications} />}
      { isOpen && <Alerts />}

      <div className={styles.layout}>
        <AsideDiary />

        <div className={styles.content}>
          <HeaderDiary setShownNotifications={setShownNotifications} />
          {children}
        </div>
      </div>

      <PortalModal overlayBlur opened={openedWebSocketDisconnected}>
        <div className="ws-modal-wrapper">
          <ButtonClose onClick={closeWebSocketDisconnected} />
          <DisconnectedWsModalContent />
        </div>
      </PortalModal>

      <AudioAlert />
    </div>
  );
});

export default DiaryLayout;
