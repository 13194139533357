export const NAVIGATION_LINKS = [
  {
    path: '/screener',
    title: 'screener',
    isAdaptive: true,
  },
  {
    path: '/trading-journal/trade-list',
    title: 'diary',
    isAdaptive: false,
  },
  {
    path: '/algo-trading',
    title: 'algoTrading',
    isAdaptive: true,
  },
  {
    path: '/alerts',
    title: 'alerts',
    isAdaptive: true,
  },
  {
    path: '/news',
    title: 'news',
    isAdaptive: true,
  },
];
