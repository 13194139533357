import qs from 'qs';
import { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from '@mantine/core';
import useMobile from 'src/shared/libs/hooks/use-mobile';
import { useTranslation } from 'react-i18next';
import BonusSlide from './slides/bonus-slide/bonus-slide';
import ApiKeysSlide from './slides/api-keys-slide/api-keys-slide';
import SecuritySlide from './slides/security-slide/security-slide';
import DocumentsSlide from './slides/documents-slide/documents-slide';
import VerificationSlide from './slides/verification-slide/verification-slide';
import './slider.scss';

const slides = [
  {
    key: 'verification',
    Component: VerificationSlide,
  },
  {
    key: 'security',
    Component: SecuritySlide,
  },
  {
    key: 'api-keys',
    Component: ApiKeysSlide,
  },
  {
    key: 'bonuses',
    Component: BonusSlide,
  }, {
    key: 'documents',
    Component: DocumentsSlide,
  },
];

const slidesRoute = ['verification', 'security', 'api-keys', 'bonuses', 'documents'];
const PROFILE_SLIDES = [
  {
    index: 0,
    title: 'Верификация',
    value: 'verification',
  },
  {
    index: 1,
    title: 'Безопасность',
    value: 'security',
  },
  {
    index: 2,
    title: 'API-ключи',
    value: 'api-keys',
  },
  {
    index: 3,
    title: 'Бонусы',
    value: 'bonuses',
  },
  {
    index: 4,
    title: 'Документы',
    value: 'documents',
  },
];

const Slider = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMobile();

  const [tabValue, setTabValue] = useState(slidesRoute[0]);
  const [tabIndex, setTabIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(slides[tabIndex]);

  const handleChoosenSlide = (index: number) => {
    setActiveTab(slides[index]);

    if (location.search) navigate('/profile');

    navigate(`/profile/${slidesRoute[index]}`);
  };

  useLayoutEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const [page, slide] = location.pathname.split('/').filter((item) => item !== '');
    const parsedParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const queryTabIndex = parsedParams?.tab;
    const queryAddWallet = parsedParams?.addWallet;

    setTabIndex(Number(queryTabIndex));

    if (slides.findIndex((_, index) => index === Number(queryTabIndex)) !== -1) {
      setActiveTab(slides[Number(queryTabIndex)]);
    } else if (slide === 'verification') {
      setTabValue(slide);
      setTabIndex(0);
      setActiveTab(slides[0]);
    } else if (slide === 'security') {
      setTabValue(slide);
      setTabIndex(1);
      setActiveTab(slides[1]);
    } else if (slide === 'api-keys') {
      setTabIndex(2);
      setTabValue(slide);
      setActiveTab(slides[2]);
    } else if (slide === 'bonuses') {
      setTabIndex(3);
      setTabValue(slide);
      setActiveTab(slides[3]);
    } else if (slide === 'documents') {
      setTabValue(slide);
      setActiveTab(slides[4]);
      setTabIndex(4);
    } else if (queryAddWallet) {
      setTabValue('security');
      setActiveTab(slides[1]);
      setTabIndex(1);

      const [profile] = document.getElementsByClassName('content');

      if (profile) {
        timeoutId = setTimeout(() => {
          profile.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          });
        }, 100);
      }
    } else {
      setTabValue('verification');
      setActiveTab(slides[0]);
      setTabIndex(0);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [location]);

  useLayoutEffect(() => {
    const reload = localStorage.getItem('reload');

    if (reload) {
      navigate('/profile');
      localStorage.removeItem('reload');
    }
  }, []);

  const handleSetTabValue = (tabName: string, tabIndex: number) => {
    setTabValue(tabName);
    setTabIndex(tabIndex);
    handleChoosenSlide(tabIndex);
  };

  return (
    <div className="slider">
      <Tabs activateTabWithKeyboard className="tabs-container" color="#00AB6D" value={tabValue} defaultValue={tabValue}>
        <Tabs.List justify="flex-start" className="tabs-navigation">
          {
            PROFILE_SLIDES.map((slide) => (
              <Tabs.Tab key={slide.index} className="tab-item" onClick={() => handleSetTabValue(slide.value, slide.index)} value={slide.value}>{t(slide.value)}</Tabs.Tab>
            ))
          }
          {!isMobile && <Tabs.Tab className="tab-item visible" value="ss">ss</Tabs.Tab>}
        </Tabs.List>

        <Tabs.Panel className="tab-content" value={tabValue}>
          <activeTab.Component />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default Slider;
