import { useEffect, useRef } from 'react';
import { Nullable } from 'src/shared/types/global-types';

export function useIntersectionObserver(
  page: number,
  totalPages: number,
  data: unknown,
  className: string,
  setPage: Function,
  maxItems?: number,
) {
  const observerRef = useRef<Nullable<IntersectionObserver>>(null);
  const count = maxItems || 19;

  useEffect(() => {
    if (!data) return () => { }; // Ждем, пока `data` не станет доступным

    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && page < totalPages) {
        setPage((prevPage: number) => prevPage + 1);
      }
    });

    return () => {
      observerRef.current?.disconnect();
    };
  }, [page, totalPages, setPage, data]); // Добавляем `data` в зависимости

  useEffect(() => {
    if (!data || !observerRef.current) return; // Проверяем наличие данных и Observer

    observerRef.current.disconnect();

    const postNodes = document.querySelectorAll(`.${className}`);
    if (postNodes.length > count) {
      observerRef.current.observe(postNodes[postNodes.length - 1]);
    }
  }, [data, className, count, totalPages]);

  return observerRef;
}
