import classNames from 'classnames';
import { memo, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as LogoIconSvg } from 'src/shared/assets/images/logotype.svg';
import { ReactComponent as DiscordIconSvg } from 'src/shared/assets/images/socials/discord.svg';
import { ReactComponent as TelegramIconSvg } from 'src/shared/assets/images/socials/telegram.svg';
import { handleSupportTelegram } from 'src/shared/libs/helpers/helper.lib';
import { DISCORD_CHANNEL } from 'src/shared/constants/app-links';
import { DIARY_MENU_ITEMS, DIARY_PAGES } from './constants';
import styles from './aside-diary.module.scss';

const AsideDiary = () => {
  const { pathname } = useLocation();
  const [activePage, setActivePage] = useState(DIARY_MENU_ITEMS[0].page);

  useEffect(() => {
    const page = pathname.split('/')[2];

    if (page) {
      const currentPage = DIARY_PAGES[page];
      if (currentPage) setActivePage(currentPage);
    } else {
      setActivePage(DIARY_PAGES.dashboard);
    }
  }, [pathname]);

  return (
    <div className={styles.aside}>
      <Link to="trading-journal/trade-list" onClick={() => setActivePage('trade-list')} className={styles.logo}>
        <LogoIconSvg />
      </Link>

      <nav className={styles.navbar}>
        <ul className={styles.navbarMenu}>
          {DIARY_MENU_ITEMS.map(({ page, icon: Icon }) => (
            <li
              key={page}
              className={classNames(styles.navbarLink, { [styles.active]: activePage === page })}
            >
              <Link onClick={() => setActivePage(page)} to={`trading-journal/${page}`}><Icon /></Link>
            </li>
          ))}
        </ul>
      </nav>

      <div className={styles.socialLinks}>
        <Link to={DISCORD_CHANNEL} target="_blank"><DiscordIconSvg className={styles.socialDiscord} /></Link>
        <TelegramIconSvg onClick={handleSupportTelegram} className={styles.socialTelegram} />
      </div>
    </div>
  );
};

export default memo(AsideDiary);
