import { createAsyncThunk } from '@reduxjs/toolkit';
import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { AxiosError } from 'axios';
import { PositionsSummary } from 'src/pages/diary/pages/trade-list/model/types';
import { VerificationId } from '../verifications/thunks';
import {
  EThemeMode,
  IpAddress, Rebates, ResponseUserKycStatus, User, UserSettings,
} from './types';
import { CreateIpAddress } from '../sub-accounts/types';

export type LoginVerificationsParams = {
  methods: string[]
} & VerificationId

export type ChangePhoneNumberParams = {
  phone_number: string;
} & VerificationId

type ResponseChangeUserPhoneNumber = {
  phone_number: string
}

export type ChangeUserTelegramParams = {
  telegram_name: string;
} & VerificationId

export type ResponseChangeUserTelegram = {
  telegram_name: string;
}

export type ChangeConnectedGAParams = {
  is_active_ga: boolean;
} & VerificationId

export type ChangeUserDiscordParams = {
  discord_name: string;
} & VerificationId

export type ResponseChangeUserDiscord = {
  discord_name: string;
}

export type CreateIpAddressParams = {
  newIpAddress: CreateIpAddress
} & VerificationId

export type DeleteIpAddress = {
  ip_address_id: number
} & VerificationId

export type ChangePassword = {
  updatePassword: {
    old_password: string,
    new_password: string
  }
} & VerificationId

export type ChangeTheme = {
  theme: EThemeMode
}

export type TableSave = {
  table_name: string,
  table_settings: {
    columns: any[]
  }
}

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get<User>(ROUTE_PATH.users.me);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchUserSettings = createAsyncThunk(
  'user/fetchUserSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get<UserSettings>(ROUTE_PATH.users.settings.settings);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchLoginVerifications = createAsyncThunk(
  'user/fetchLoginVerifications',
  async (params: LoginVerificationsParams, { rejectWithValue }) => {
    try {
      const response = await $api.put<boolean>(ROUTE_PATH.users.settings.loginVerify, { methods: params.methods }, {
        headers: {
          'verification-id': params.verification_id,
        },
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchChangeUserPhoneNumber = createAsyncThunk(
  'user/fetchChangeUserPhoneNumber',
  async (params: ChangePhoneNumberParams) => {
    const response = await $api.put<ResponseChangeUserPhoneNumber>(ROUTE_PATH.users.settings.phoneNumber, { phone_number: params.phone_number }, {
      headers: {
        'verification-id': params.verification_id,
      },
    });
    const { data } = response;
    return data.phone_number;
  },
);

export const fetchSaveUserOldPhoneNumber = createAsyncThunk(
  'user/fetchSaveUserOldPhoneNumber',
  async (params: ChangePhoneNumberParams) => {
    const response = await $api.post<boolean>(ROUTE_PATH.users.settings.phoneNumber, { phone_number: params.phone_number }, {
      headers: {
        'verification-id': params.verification_id,
      },
    });
    const { data } = response;
    return data;
  },
);

export const fetchChangeUserDiscord = createAsyncThunk(
  'user/fetchChangeUserDiscord',
  async (params: ChangeUserDiscordParams) => {
    const response = await $api.put<ResponseChangeUserDiscord>(ROUTE_PATH.users.settings.discord, { discord_name: params.discord_name }, {
      headers: {
        'verification-id': params.verification_id,
      },
    });
    const { data } = response;
    return data.discord_name;
  },
);

export const fetchChangeUserTelegram = createAsyncThunk(
  'user/fetchChangeUserTelegram',
  async (params: ChangeUserTelegramParams) => {
    const response = await $api.put<ResponseChangeUserTelegram>(ROUTE_PATH.users.settings.telegram, { telegram_name: params.telegram_name }, {
      headers: {
        'verification-id': params.verification_id,
      },
    });
    const { data } = response;
    return data.telegram_name;
  },
);

export const fetchChangeConnectedGA = createAsyncThunk(
  'user/fetchChangeConnectedGA',
  async (params: ChangeConnectedGAParams) => {
    try {
      const response = await $api.put(ROUTE_PATH.users.settings.ga, { is_active_ga: params.is_active_ga }, {
        headers: {
          'verification-id': params.verification_id,
        },
      });
      const { data } = response;

      if (data === undefined) throw response;

      return { data, type: typeof data === 'boolean' ? 'disabled' : 'enabled' };
    } catch (error) {
      throw error;
    }
  },
);

export const fetchChangeSmsType = createAsyncThunk(
  'verifications/fetchChangeSmsType',
  async (typeSms: string, { rejectWithValue }) => {
    try {
      const response = await $api.put<boolean>(ROUTE_PATH.users.settings.typeSms, { type_sms_code: typeSms });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchUserIpAddresses = createAsyncThunk(
  'user/fetchUserIpAddresses',
  async () => {
    try {
      const response = await $api.get<IpAddress[]>(ROUTE_PATH.users.ipAddress);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchUserAddIpAddresses = createAsyncThunk(
  'user/fetchUserAddIpAddresses',
  async (params: CreateIpAddressParams, { rejectWithValue }) => {
    try {
      const response = await $api.post<IpAddress>(ROUTE_PATH.users.ipAddress, params.newIpAddress, {
        headers: {
          'verification-id': params.verification_id,
        },
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchUserDeleteIpAddresses = createAsyncThunk(
  'user/fetchUserDeleteIpAddresses',
  async (params: DeleteIpAddress, { rejectWithValue }) => {
    try {
      const response = await $api.delete(ROUTE_PATH.users.ipAddress, {
        data: {
          ip_address_id: params.ip_address_id,
        },
        headers: {
          'verification-id': params.verification_id,
        },
      });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchUserKycStatus = createAsyncThunk(
  'user/fetchUserKycStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get<ResponseUserKycStatus>(ROUTE_PATH.users.settings.kyc);
      const { data } = response;

      if (data === undefined) throw response;

      return data.status;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchChangePassword = createAsyncThunk(
  'user/fetchChangePassword',
  async (params: ChangePassword, { rejectWithValue }) => {
    try {
      const response = await $api.put(ROUTE_PATH.users.settings.changePassword, params.updatePassword, {
        headers: {
          'verification-id': params.verification_id,
        },
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchTradeCategories = createAsyncThunk(
  'user/fetchTradeCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get<string[]>(ROUTE_PATH.users.tradeCategories);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchUserRebatesSettings = createAsyncThunk(
  'user/fetchUserRebatesSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get<Rebates[]>(ROUTE_PATH.users.rebates.settings);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchChangeTheme = createAsyncThunk(
  'user/fetchChangeTheme',
  async (params: ChangeTheme, { rejectWithValue }) => {
    try {
      const response = await $api.put(ROUTE_PATH.users.settings.theme, params);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchChangeLanguage = createAsyncThunk(
  'user/fetchChangeLanguage',
  async (language: string, { rejectWithValue }) => {
    try {
      const response = await $api.put<{ language: string }>(ROUTE_PATH.users.settings.language, { language });
      const { data } = response;

      if (data === undefined) throw response;

      return data.language;
    } catch (error) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchTableSave = createAsyncThunk(
  'user/fetchTableSave',
  async (params: TableSave, { rejectWithValue }) => {
    try {
      const response = await $api.put(ROUTE_PATH.users.settings.tableColumnsSave, params);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);
