import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

import { store } from 'src/app/store/store';
import { addSubsciptionQueue, removeSubsciptionQueue } from 'src/app/store/slices/subscriptions/slice';
import { $api } from 'src/shared/api/axios.instance';
import { socket } from 'src/shared/api/websocket.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { devConsoleLog } from 'src/shared/libs/helpers/helper.lib';
import {
  EInputEventType, EOutputEventType, ISubscribeEvent, IUnsubscribeEvent,
} from 'src/shared/types/ws-interfaces';
import { TransfersHistoryResponse, WithdrawalDetail } from './types';

export type TransferHistorySortByColumns = 'created_at' | 'quantity'

export type TransfersHistoryParams = {
  from_date: string;
  to_date: string;
  transfer_type?: string;
  symbol?: string;
  sub_account_id?: number;
  page: number;
  limit: number;
  sort_by?: TransferHistorySortByColumns,
  order_by?: 'asc' | 'desc'
}

export type WithdrawalDetailParams = {
  withdrawal_request_id: number
}

export const subscribeTransfersHistory = () => {
  const { dispatch } = store;

  const intervalId = setInterval(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      devConsoleLog('Подписка на Transfers history');

      const subscribeMessage: ISubscribeEvent = {
        event: EInputEventType.SUBSCRIBE,
        metadata: {
          event_type: EOutputEventType.TRANSFERS,
        },
      };

      socket.send(JSON.stringify(subscribeMessage));
      dispatch(addSubsciptionQueue(subscribeMessage));
      clearInterval(intervalId);
    }
  }, 500);
};

export const unsubscribeTransfersHistory = () => {
  const { dispatch } = store;

  if (socket && socket.readyState === WebSocket.OPEN) {
    devConsoleLog('Отписка от Transfers history');

    const unsubscribeMessage: IUnsubscribeEvent = {
      event: EInputEventType.UNSUBSCRIBE,
      metadata: {
        event_type: EOutputEventType.TRANSFERS,
      },
    };

    socket.send(JSON.stringify(unsubscribeMessage));
    dispatch(removeSubsciptionQueue(unsubscribeMessage.metadata.event_type));
  }
};

export const fetchTransfersHistory = createAsyncThunk(
  'transfersHistory/fetchTransfers',
  async (params: TransfersHistoryParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TransfersHistoryResponse> = await $api.get(ROUTE_PATH.transfers, { params });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchWithdrawalDetail = createAsyncThunk(
  'transfersHistory/fetchWithdrawalDetail',
  async (params: WithdrawalDetailParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<WithdrawalDetail> = await $api.get(ROUTE_PATH.withdrawalAttributess, { params });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);
