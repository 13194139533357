import {
  useCallback, useEffect, useLayoutEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { Exchange } from 'src/app/store/slices/exchanges/types';
import { Nullable } from 'src/shared/types/global-types';
import { useAppDispatch } from 'src/app/store/store';
import { SubAccountType } from 'src/app/store/slices/sub-accounts/types';
import { KEYBOARD_LAYOUT } from 'src/shared/constants/constants';
import { selectSubAccounts } from 'src/app/store/slices/sub-accounts/selectors';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';
import { clearSubAccountSettings } from 'src/app/store/slices/sub-accounts/slice';
import { fetchSubAccountSettings } from 'src/app/store/slices/sub-accounts/thunks';
import {
  debounce, isDetailError, removeItemFromLocalStorage, saveToLocalStorage, sortSubAccountsByFavorite,
} from 'src/shared/libs/helpers/helper.lib';

import Select from 'src/shared/ui/select/select';
import useAlert from 'src/shared/libs/hooks/use-alert';
import GlassIcon from 'src/shared/assets/icons/glass-icon/glass-icon';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import InputText from 'src/shared/ui/input/input-text/input-text';
import useExchange from 'src/shared/libs/hooks/use-exchange';
import SelectSkeleton from 'src/shared/ui/skeleton/select-skeleton/select-skeleton';
import DefaultIconSvg from 'src/shared/assets/images/lot.png';
import AutoLeverages from './components/auto-leverages/auto-leverages';
import MarginModeSetting from './components/margin-mode-setting/margin-mode-setting';
import ChangeLeverageAllInstruments from './components/change-leverage-all-instruments/change-leverage-all-instruments';
import AutoChangesLeverages from './components/auto-changes-leverages/auto-changes-leverages';
import AutoLeveragesCalculator from './components/auto-leverages-calculator/auto-leverages-calculator';
import ChnageLeverageInstrument from './components/change-leverage-instrument/change-leverage-instrument';
import { selectLeverages } from './model/selectors';
import { clearInstruments } from './model/slice';
import { LeverageInstrument, InstrumentParams } from './model/types';
import {
  fetchInstruments,
  InstrumentsParams,
  SetLeverageParams,
  fetchSetInstrumentParams,
  fetchSetLeverageInstrument,
} from './model/thunks';
import {
  CALCULATION_LEVERAGE_ERROR_MSG, MARGIN_MODE,
} from './constants';
import { useIntersectionObserver } from '../../shared/libs/hooks/use-observer';
import TableLeverages from './components/table-leverages/table-leverages';
import './leverages.scss';

const pageTitle = 'leverages';

const Leverages = () => {
  const { t } = useTranslation();
  useChangePageTitle(pageTitle);

  const dispatch = useAppDispatch();
  const { setAlertMessage } = useAlert();
  const { getExchangeImageById, availableExchanges: exchanges } = useExchange();

  const subAccounts = useSelector(selectSubAccounts);
  const { instruments, updateInstrument } = useSelector(selectLeverages);

  const [page, setPage] = useState(1);
  const [openCalculator, setOpenCalculator] = useState(false);
  const [currentInstrument, setCurrentInstrument] = useState<Nullable<LeverageInstrument>>(null);
  const [currentExchange, setCurrentExchange] = useState<Nullable<Exchange>>(null);
  const [currentSubAccount, setCurrentSubAccount] = useState<Nullable<SubAccountType>>(null);
  const [subAccountId, setSubAccountId] = useState<Nullable<number>>(null);
  const [selectedInstrumentIds, setSelectedInstrumentIds] = useState<number[]>([]);
  const [excludedInstrumentIds, setExcludedInstrumentIds] = useState<number[]>([]);
  const [selectedLeverageValue, setSelectedLeverageValue] = useState(0);

  const totalPage = instruments ? instruments.meta.total_pages : 1;

  // Calculator state
  const [searchValue, setSearchValue] = useState('');
  const [marginMode, setMarginMode] = useState(2);
  const [instrumentId, setIntrumentId] = useState(1);
  const [choosenBracket, setChoosenBracket] = useState<number[]>([]);

  const [autoCalculator, setAutoCalculator] = useState(false);
  const [openLeverage, setOpenLeverage] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [totalInstruments, setTotalInstruments] = useState<Nullable<number>>(Number(localStorage.getItem('totalInstruments')));
  const marginModeOfSelectedInstrument = MARGIN_MODE.find((item) => item.value === marginMode);

  useIntersectionObserver(page, totalPage, instruments?.items, 'post', setPage);

  const debouncedFetch = useCallback(
    debounce((queryParams: InstrumentsParams) => {
      dispatch(clearInstruments());
      dispatch(fetchInstruments(queryParams));
    }, 500) as any,
    [dispatch],
  );

  const handleOpenCalculcator = (instrument: LeverageInstrument) => {
    handleSelectedInstrumentId(instrument.instrument.id);

    setCurrentInstrument(instrument);
    setOpenCalculator(true);
    setIntrumentId(instrument.instrument.id);

    if (instrument.margin_mode) {
      setMarginMode(instrument.margin_mode);
    } else {
      setMarginMode(1);
    }
  };

  const handleCloseCalculcator = () => {
    setOpenCalculator(false);
    setIntrumentId(0);
    setSelectedInstrumentIds([]);
  };

  const handleSaveCalculcator = async () => {
    if (subAccountId) {
      const params: InstrumentParams = {
        instrument_id: instrumentId,
        choosen_bracket: choosenBracket,
      };

      if (marginMode) {
        params.margin_mode = marginMode;
      }

      const { payload } = await dispatch(fetchSetInstrumentParams({ params, subAccountId }));

      if (payload === true) {
        setAlertMessage('settings_saved_successfully', 'success');
      } else if (payload === 'string') {
        setAlertMessage(payload, 'warning');
      } else {
        setAlertMessage('negative_response', 'error');
      }
    }
  };

  const handleSetExchange = (exchange: Exchange) => {
    if (exchange.id === currentExchange?.id) return;

    setPage(1);
    setSearchValue('');
    dispatch(clearInstruments());
    setOpenCalculator(false);
    setCurrentInstrument(null);
    setSelectedInstrumentIds([]);
    setExcludedInstrumentIds([]);
    setSelectAll(false);

    const firstSubAccount = subAccounts?.find((subAccount) => subAccount.exchange_id === exchange.id);

    if (firstSubAccount) {
      setCurrentSubAccount(firstSubAccount);
      setSubAccountId(firstSubAccount.id);
    }

    setCurrentExchange(exchange);
  };

  const handleSetSubAccount = (subAccount: SubAccountType) => {
    if (subAccount.id === subAccountId) return;

    dispatch(clearInstruments());
    setPage(1);
    setSearchValue('');
    setSubAccountId(subAccount.id);
    setCurrentSubAccount(subAccount);
    setCurrentInstrument(null);
    setSelectedInstrumentIds([]);
    setExcludedInstrumentIds([]);
    setSelectAll(false);
  };

  const handleSetLeverage = async () => {
    if (!subAccountId) return;

    try {
      const params: SetLeverageParams = {
        choosen_bracket: selectedLeverageValue,
        sub_account_id: subAccountId,
      };

      if (!excludedInstrumentIds.length && !selectAll) {
        params.instrument_ids = selectedInstrumentIds;
      }

      if (searchValue.length && !excludedInstrumentIds.length && !selectAll) {
        params.instrument_ids = selectedInstrumentIds;
      }

      if (excludedInstrumentIds.length) {
        params.excluded_instrument_ids = excludedInstrumentIds;
      }

      if (selectAll && !excludedInstrumentIds.length) {
        params.excluded_instrument_ids = [];
      }

      const response = await dispatch(fetchSetLeverageInstrument(params));
      const payload = unwrapResult(response);

      if (payload === true) {
        setAlertMessage('leverage_calculation_in_process', 'warning');
        setSelectedInstrumentIds([]);
        setSelectAll(false);
        removeItemFromLocalStorage('instrumentIds');
      } else if (isDetailError(payload)) {
        const message = CALCULATION_LEVERAGE_ERROR_MSG[payload.detail] || CALCULATION_LEVERAGE_ERROR_MSG.default;
        setAlertMessage(message.msg, message.type);
      } else {
        const responseMessage = typeof payload === 'string' ? payload : JSON.stringify(payload);
        setAlertMessage(responseMessage, 'error');
      }
    } catch (error) {
      const message = typeof error === 'string' ? CALCULATION_LEVERAGE_ERROR_MSG[error] : CALCULATION_LEVERAGE_ERROR_MSG.default;
      setAlertMessage(message.msg, message.type);
    }
  };

  const handleOnChangeTransliterate = (value: string) => {
    const latinText: string = value
      .split('')
      .map((char) => KEYBOARD_LAYOUT[char] || char)
      .join('');

    setSearchValue(latinText);
  };

  const handleSelectedAllInstrumentIds = () => {
    const instrumentIds = instruments?.items.map((item) => item.instrument.id);

    if (instrumentIds && selectedInstrumentIds.length < 1) {
      setSelectedInstrumentIds(instrumentIds);
      setSelectAll(true);
    } else {
      setSelectedInstrumentIds([]);
      setSelectAll(false);
    }
  };

  const handleSelectedInstrumentId = (instrumentId: number) => {
    setSelectedInstrumentIds((prev) => (prev.includes(instrumentId)
      ? prev.filter((id) => id !== instrumentId)
      : [...prev, instrumentId]));
  };

  useEffect(() => {
    if (instruments && selectedInstrumentIds.length) {
      if (selectedInstrumentIds.length === 1) {
        const findInstrument = instruments.items.find((instrument) => instrument.instrument.id === selectedInstrumentIds[0]);
        if (findInstrument) {
          setCurrentInstrument(findInstrument);
          setIntrumentId(findInstrument.instrument.id);
        }
      }
    }
  }, [selectedInstrumentIds, instruments]);

  useEffect(() => {
    removeItemFromLocalStorage('instrumentIds');

    return () => {
      dispatch(clearInstruments());
      dispatch(clearSubAccountSettings());
      removeItemFromLocalStorage('totalInstruments');
    };
  }, []);

  useEffect(() => {
    if (subAccountId) {
      dispatch(fetchSubAccountSettings(subAccountId));
    }
  }, [subAccountId]);

  useEffect(() => {
    if (!subAccounts || !exchanges) return;

    const favoriteSubAccount = subAccounts.find((subAccount) => subAccount.is_favorite);
    const exchangeByFavoriteSubAccount = exchanges.find((exchange) => exchange.id === favoriteSubAccount?.exchange_id);

    if (favoriteSubAccount) {
      setCurrentSubAccount(favoriteSubAccount);
      setSubAccountId(favoriteSubAccount.id);
    } else {
      const [favoriteSubAccount] = subAccounts.slice().sort(sortSubAccountsByFavorite);
      const [currentExchange] = exchanges;

      if (favoriteSubAccount) {
        setCurrentSubAccount(favoriteSubAccount);
        setSubAccountId(favoriteSubAccount.id);
      }

      if (currentExchange) setCurrentExchange(currentExchange);
    }

    if (exchangeByFavoriteSubAccount) setCurrentExchange(exchangeByFavoriteSubAccount);
  }, [subAccounts, exchanges]);

  useEffect(() => {
    if (subAccountId) {
      let queryParams: InstrumentsParams = {
        limit: 20,
        page,
        subAccountId,
      };

      if (searchValue && searchValue.length > 1) {
        setPage(1);
        queryParams.symbol = searchValue.toUpperCase();
        debouncedFetch.execute(queryParams);
        return;
      }

      if (searchValue.length === 0) {
        debouncedFetch.stop();
        dispatch(fetchInstruments(queryParams));
      }
    }
  }, [page, searchValue, subAccountId]);

  useEffect(() => {
    if (updateInstrument && currentInstrument && Object.keys(currentInstrument).length !== 0) {
      if (updateInstrument.instrument_id === currentInstrument?.instrument.id) {
        setMarginMode(updateInstrument.margin_mode);
      }
    }
  }, [updateInstrument, currentInstrument]);

  useEffect(() => {
    if (!instruments || !selectAll) return;

    const getInstrumentsIdsLS = localStorage.getItem('instrumentIds');
    const getInstrumentsIds = getInstrumentsIdsLS ? JSON.parse(getInstrumentsIdsLS) : []; //  ПОЛУЧАЕМ ВЫБРАННЫЕ ID ИЗ LS

    const instrument = instruments?.items.map((item) => item.instrument.id);
    const selected = selectedInstrumentIds;

    const combined = [...instrument, ...selected];
    const unique = combined.filter((item) => combined.indexOf(item) === combined.lastIndexOf(item));

    const combinedIsLS = searchValue.length ? [...new Set([...getInstrumentsIds, ...unique])] : [...new Set([...unique])]; // НОВЫЕ + ТЕ, ЧТО БЫЛИ В LS

    localStorage.setItem('instrumentIds', JSON.stringify(combinedIsLS));
    setExcludedInstrumentIds(combinedIsLS);
  }, [selectedInstrumentIds, selectAll]);

  useEffect(() => {
    if (instruments) {
      const totalInstruments = instruments.meta.total_items;
      const checkTotalInstruments = localStorage.getItem('totalInstruments');

      if (!checkTotalInstruments) {
        setTotalInstruments(totalInstruments);
        saveToLocalStorage('totalInstruments', totalInstruments);
      } else {
        setTotalInstruments(totalInstruments);
        saveToLocalStorage('totalInstruments', totalInstruments);
      }
    }
  }, [instruments]);

  useLayoutEffect(() => {
    if (selectAll) {
      const searchInstrumentIds = instruments?.items.map((item) => item.instrument.id);
      if (searchInstrumentIds) setSelectedInstrumentIds(searchInstrumentIds);
    } else {
      removeItemFromLocalStorage('instrumentIds');
      setExcludedInstrumentIds([]);
    }
  }, [selectAll, instruments]);

  useLayoutEffect(() => {
    if (!selectAll) return;

    // ! ИСКЛЮЧАЕМ ИЗ ВЫБРАННЫХ ТЕ ИНСТУРМЕНТЫ ЧТО УЖЕ В ИСКЛЮЧЕНИИ
    const getInstrumentsIdsLS = localStorage.getItem('instrumentIds');
    const getInstrumentsIds = getInstrumentsIdsLS ? JSON.parse(getInstrumentsIdsLS) : []; // ! ПОЛУЧАЕМ ВЫБРАННЫЕ ID ИЗ LS
    const selectedInstrument = instruments?.items.map((item) => item.instrument.id) ?? [];

    const selectedsNotExcludes = selectedInstrument.filter((item) => !getInstrumentsIds.includes(item));
    setSelectedInstrumentIds(selectedsNotExcludes);
  }, [instruments, selectAll]);

  return (
    <div className="leverage-page-wrapper">
      <div className="section-name">
        <h1>{t('leverage_settings')}</h1>
      </div>

      <div className="section-content">
        <div className="instrument-list-container">
          <div className="instrument-list-header">
            <div className="header-filters-container">
              <InputForm maxWidth="400px" title={t('search')}>
                <InputText
                  value={searchValue}
                  placeholder={t('instrument_search')}
                  onChange={handleOnChangeTransliterate}
                ><GlassIcon />
                </InputText>
              </InputForm>

              <InputForm maxWidth="200px" title={t('exchange')}>
                {exchanges && currentExchange ? (
                  <Select
                    isActiveSearch={false}
                    isSelectValue={(
                      <div key={currentExchange.name} className="select-item">
                        <img src={currentExchange.image} alt="exchange" />
                        <span className="short-name">{currentExchange.name}</span>
                      </div>
                    )}
                  >
                    {
                      exchanges.map((exchange) => (
                        <div
                          key={exchange.name}
                          className="select-item"
                          role="button"
                          tabIndex={0}
                          onClick={() => handleSetExchange(exchange)}
                          onKeyDown={() => {}}
                        >
                          <img src={exchange.image} alt="exchange" />
                          <span className="short-name">{exchange.name}</span>
                        </div>
                      ))
                    }
                  </Select>
                ) : <SelectSkeleton /> }
              </InputForm>

              <InputForm maxWidth="200px" title={t('account')}>
                {subAccounts && currentSubAccount ? (
                  <Select
                    isActiveSearch={false}
                    isSelectValue={(
                      <div
                        className="select-item"
                      >
                        <img src={getExchangeImageById(currentSubAccount.exchange_id) || DefaultIconSvg} alt="subAccount" />
                        <span className="short-name">{currentSubAccount.user_name}</span>
                      </div>
                    )}
                  >
                    { subAccounts.filter((subAccount) => subAccount.exchange_id === currentExchange?.id).sort(sortSubAccountsByFavorite).map((account) => (
                      <div
                        key={account.id}
                        className="select-item"
                        role="button"
                        tabIndex={0}
                        onClick={() => handleSetSubAccount(account)}
                        onKeyDown={() => handleSetSubAccount(account)}
                      >
                        <img src={getExchangeImageById(account.exchange_id) || DefaultIconSvg} alt="subAccount" />
                        <span className="short-name">{account.user_name}</span>
                      </div>
                    ))}
                  </Select>
                ) : <SelectSkeleton /> }
              </InputForm>
            </div>
          </div>

          <TableLeverages
            currentSubAccount={currentSubAccount}
            instruments={instruments?.items}
            openCalculator={handleOpenCalculcator}
            searchValue={searchValue}
            selectedAllInstrumentIds={handleSelectedAllInstrumentIds}
            selectedInstrumentIds={selectedInstrumentIds}
          />
        </div>

        <div className="settings-container">
          <ChangeLeverageAllInstruments
            opened={((openLeverage && selectedInstrumentIds.length > 1) || excludedInstrumentIds.length > 0 || selectAll)}
            selectedInstrumentIds={selectedInstrumentIds}
            excludedInstrumentIds={excludedInstrumentIds}
            selectAllInstruments={selectAll}
            totalInstruments={totalInstruments}
            selectedLeverageValue={selectedLeverageValue}
            setSelectedLeverageValue={setSelectedLeverageValue}
            setLeverage={handleSetLeverage}
          />

          <ChnageLeverageInstrument
            opened={openCalculator && !selectAll && selectedInstrumentIds.length === 1 && excludedInstrumentIds.length === 0}
            close={handleCloseCalculcator}
            saveSettings={handleSaveCalculcator}
            instrument={currentInstrument}
            setChoosenBracket={setChoosenBracket}
            setMarginMode={setMarginMode}
            marginModeOfSelectedInstrument={marginModeOfSelectedInstrument}
          />

          <AutoLeverages openCalculator={autoCalculator} setAutoCalculator={setAutoCalculator} subAccountId={subAccountId} />
          <AutoLeveragesCalculator openAutoCalculator={!autoCalculator} subAccountId={subAccountId} />
          <AutoChangesLeverages />
          <MarginModeSetting subAccountId={subAccountId} />
        </div>
      </div>
    </div>
  );
};

export default Leverages;
