import { Dispatch } from '@reduxjs/toolkit';
import { updateBalance, updateTotalBalance } from 'src/app/store/slices/balance/slice';
import {
  EInputEventType, EOutputEventType, EventTypesAll, ISubscribeEvent,
} from 'src/shared/types/ws-interfaces';
import {
  removeOrderById, removePositionById, setOrders, setPositions, setPositionsPnl, updateOrder, updateOrdersInstrument, updatePosition, updatePositionsInstrument,
} from 'src/pages/trading/model/slice';
import { store } from 'src/app/store/store';
import {
  removeCalculationFromQueue, removeMarginModeQueue, setCalculatorDetailMessage, updateInstrument, updateMarginMode,
} from 'src/pages/leverages/model/slice';
import { InstrumentUpdate, LeveregesCalculatorDetail } from 'src/pages/leverages/model/types';
import { SubAccountAssetRemove } from 'src/app/store/slices/sub-accounts/types';
import {
  addSubAccountAsset,
  removeSubAccountAsset,
  updateSubAccountAsset,
  updateSubAccountAssetInstrument,
} from 'src/app/store/slices/sub-accounts/slice';
import { setKycStatus, setKycVerify } from 'src/app/store/slices/user/slice';
import { EKycStatus } from 'src/app/store/slices/user/types';
import {
  addPositionsSummury,
  removePositionsSummury,
  updatePositionsSummury,
  updatePositionsSummuryInstrument,
} from 'src/pages/account/model/slice';
import { addTransferHistory } from 'src/widgets/transfer-history/model/slice';
import { addNotification, deleteNotification, markReadByIds } from 'src/widgets/notifications/model/slice';
import { setHandleConnectedWebSocket, setSystemAlert } from 'src/app/store/slices/alert/slice';
import {
  addPositionSummary, addTradeSummary, updatePositionSummaryTrades, updatePositionSummuryDiary, updateTradeSummary,
} from 'src/pages/diary/pages/trade-list/model/slice';
import i18next from 'i18next';
import { batch } from 'react-redux';
import { WEB_SOCKET_URL } from '../constants/constants';
import { getTokensFromLocalStorage } from '../libs/helpers/save-tokens';
import {
  devConsoleLog, getSubAccountName,
} from '../libs/helpers/helper.lib';
import { Nullable } from '../types/global-types';
import {
  formartFundingFeesProcess,
  formatInstrumentProcess, formatOrderProcess, formatPositionProcess, formatSubAccountAssetProcess,
} from '../processors';

export let socket: Nullable<WebSocket> = null;

let pingInterval: NodeJS.Timeout;
let heartBeatInterval: NodeJS.Timeout;
let heartBeatTimeoutStart = false;
let heartBeatTimeoutSecond = 5;

const HEARTBEAT_TIMEOUT = 5000;
const HEARTBEAT_INTERVAL = 1000;
const RECONNECT_TIMEOUT = 2000;

const webSocketStatus = {
  onClose: 1005,
  tokexExpired: 4401,
  tokenMissing: 1006,
  stop: 1000,
};

export const connectWebSocket = () => (dispatch: Dispatch) => {
  if (socket) {
    socket.close();
    socket = null;
  }

  const tokens = getTokensFromLocalStorage();

  if (!socket && tokens) {
    try {
      const url = WEB_SOCKET_URL + tokens.access_token;
      socket = new WebSocket(url);

      socket.onopen = () => {
        devConsoleLog('Web socket connection established');

        heartBeatTimeoutStart = false;

        ping();
        subscriptionsRecovery();

        pingInterval = setInterval(() => {
          ping();
        }, HEARTBEAT_TIMEOUT);
      };

      socket.onmessage = (event: MessageEvent) => {
        let message: EventTypesAll;

        try {
          if (event.data === 'PING') return;

          message = JSON.parse(event.data);
          onMessage(message, dispatch);
        } catch (error) {
          console.debug('Error while parsing JSON:', error);
        }
      };

      socket.onerror = (error) => {
        devConsoleLog('The server cannot process your request:', error);

        clearInterval(pingInterval);
        clearInterval(heartBeatInterval);
      };

      socket.onclose = (event) => {
        if (event.code !== webSocketStatus.onClose) dispatch(setHandleConnectedWebSocket(true));

        const now = new Date();
        const currentDateTime = now.toLocaleString();

        clearInterval(pingInterval);
        clearInterval(heartBeatInterval);

        if (event.code === webSocketStatus.onClose) {
          devConsoleLog(`[${currentDateTime}] - The websocket has been shut down: ${event.code}`);
        } else if (event.code === webSocketStatus.tokexExpired) {
          devConsoleLog('Updating the token and restoring the WebSocket connection...', event.code);
        } else if (event.code === webSocketStatus.tokenMissing) {
          devConsoleLog(`[${currentDateTime}] - The websocket was closed with the code: ${event.code}`);
          devConsoleLog('close event:', event);

          setTimeout(() => {
            connectWebSocket()(dispatch);
          }, RECONNECT_TIMEOUT);
        } else if (event.code === webSocketStatus.stop) {
          devConsoleLog(`[${currentDateTime}] - The websocket was closed with the code: ${event.code}`);
          devConsoleLog('close event:', event);

          setTimeout(() => {
            connectWebSocket()(dispatch);
          }, RECONNECT_TIMEOUT);
        } else {
          devConsoleLog(`[${currentDateTime}] - The websocket was closed with the code:: ${event.code}`);
        }
      };
    } catch (error) {
      devConsoleLog('WebSocket catch block:', error);
    }
  }
};

function onMessage(message: EventTypesAll, dispatch: Dispatch) {
  const {
    KYC,
    PONG,
    ORDERS,
    BALANCES,
    LEVERAGES,
    TOTAL_BALANCE,
    MARGIN_MODE,
    POSITIONS,
    COMMON_PNL,
    INSTRUMENTS,
    SUB_ACCOUNT_ASSETS,
    POSITIONS_SUMMARY,
    TRANSFERS,
    NOTIFICATIONS,
    SYSTEM,
    TRADES_SUMMARY,
    FUNDING_FEES,
  } = EOutputEventType;

  const kycEvent = message.event === KYC;
  const pongEvent = message.event === PONG;
  const ordersEvent = message.event === ORDERS;
  const balanceEvent = message.event === BALANCES;
  const leverageEvent = message.event === LEVERAGES;
  const totalBalanceEvent = message.event === TOTAL_BALANCE;
  const leverageMarginModeEvent = message.event === MARGIN_MODE;
  const positionsEvent = message.event === POSITIONS;
  const commonPnlEvent = message.event === COMMON_PNL;
  const instumentEvent = message.event === INSTRUMENTS;
  const subAccountAssetEvent = message.event === SUB_ACCOUNT_ASSETS;
  const leverageCalculatorEvent = message.event === LEVERAGES;
  const positionsSummuryEvent = message.event === POSITIONS_SUMMARY;
  const transfersEvent = message.event === TRANSFERS;
  const notificationsEvent = message.event === NOTIFICATIONS;
  const systemEvent = message.event === SYSTEM;
  const tradesSummary = message.event === TRADES_SUMMARY;
  const fundingFees = message.event === FUNDING_FEES;

  const eventType = message.metadata.event_type;

  if (systemEvent) {
    if (eventType === 'MAIN_FRONTEND_UPDATED') dispatch(setSystemAlert(true));
  }

  if (eventType) {
    clearInterval(heartBeatInterval);
    heartBeatTimeoutStart = false;
  }

  if (pongEvent) {
    if (eventType === 'PONG') {
      // devConsoleLog('PONG');
    }
  }

  if (kycEvent) {
    if (eventType === 'UPDATE') {
      const { status } = message.metadata.data;
      devConsoleLog('kycStatus', status);

      dispatch(setKycStatus(status));

      if (status === EKycStatus.VERIFIED) dispatch(setKycVerify(true));
      if (status === EKycStatus.NOT_VERIFIED) dispatch(setKycVerify(false));
    }
  }

  if (tradesSummary) {
    if (eventType === 'CREATE') {
      const tradeSummary = message.metadata.data;

      dispatch(addTradeSummary(tradeSummary));
    }

    if (eventType === 'UPDATE') {
      const tradeSummaryUpdate = message.metadata.data;

      dispatch(updateTradeSummary(tradeSummaryUpdate));
    }
  }

  if (fundingFees) {
    if (eventType === 'CREATE') {
      const { data } = message.metadata;
      const process = formartFundingFeesProcess(data);

      if (process && !Array.isArray(process)) {
        dispatch(addTradeSummary(process));
      }
    }
  }

  if (positionsSummuryEvent) {
    if (eventType === 'CREATE') {
      const positionSummury = message.metadata.data;

      // In case of failure and missing instument, cancel the adding action
      if (!positionSummury.instrument) {
        console.debug('WEBSOCKET: Error when adding a new item due to missing instument in the item object.');
        return;
      }

      batch(() => {
        dispatch(addPositionsSummury(positionSummury));
        dispatch(addPositionSummary(positionSummury));
      });
    }

    if (eventType === 'UPDATE') {
      const positionSummury = message.metadata.data;

      batch(() => {
        dispatch(updatePositionsSummury(positionSummury));
        dispatch(updatePositionSummuryDiary(positionSummury));
        dispatch(updatePositionSummaryTrades(positionSummury));
      });
    }

    if (eventType === 'REMOVE') {
      const { id: positionSummuryId } = message.metadata.data;
      dispatch(removePositionsSummury(positionSummuryId));
    }
  }

  if (positionsEvent) {
    if (eventType === 'CREATE') {
      const position = message.metadata.data;

      // In case of failure and missing instument, cancel the adding action
      if (!position.instrument) return;

      const positionFormatted = formatPositionProcess(position);

      dispatch(setPositions(positionFormatted));
    }

    if (eventType === 'UPDATE') {
      const position = message.metadata.data;
      dispatch(updatePosition(position));
    }

    if (eventType === 'REMOVE') {
      const position = message.metadata.data;
      dispatch(removePositionById(position.id));
    }
  }

  if (ordersEvent) {
    if (eventType === 'CREATE') {
      const order = message.metadata.data;

      // In case of failure and missing instument, cancel the adding action
      if (!order.instrument) return;

      const orderFormatted = formatOrderProcess(order);

      dispatch(setOrders(orderFormatted));
    }

    if (eventType === 'UPDATE') {
      const order = message.metadata.data;

      dispatch(updateOrder(order));
    }

    if (eventType === 'REMOVE') {
      const order = message.metadata.data;

      dispatch(removeOrderById(order.id));
    }
  }

  if (commonPnlEvent) {
    if (eventType === 'UPDATE') {
      const pnl = message.metadata.data;
      dispatch(setPositionsPnl(pnl));
    }
  }

  if (leverageEvent) {
    if (eventType === 'UPDATE') {
      const updatedInstrument: InstrumentUpdate = message.metadata.data;
      dispatch(updateInstrument(updatedInstrument));
    }
  }

  if (leverageMarginModeEvent) {
    if (eventType === 'UPDATE') {
      const updatedInstrument = message.metadata.data;
      devConsoleLog('updatedInstrument :', updatedInstrument);

      dispatch(updateMarginMode(updatedInstrument));
    }

    if (eventType === 'FINISHED') {
      const { sub_account_id: subAccountId } = message.metadata.data;
      devConsoleLog('(margin_mode) subId:', subAccountId);

      dispatch(removeMarginModeQueue(Number(subAccountId)));
    }
  }

  if (leverageCalculatorEvent) {
    if (eventType === 'CALCULATOR:FINISHED') {
      const { detail, sub_account_id: subAccountId } = message.metadata.data;
      devConsoleLog('FINISHED CALCULATION SubAccount: ', subAccountId);

      if (detail) {
        const detailInfo: LeveregesCalculatorDetail = {
          detail,
          type: 'error',
        };
        dispatch(setCalculatorDetailMessage(detailInfo));
      } else {
        const subAccountName = getSubAccountName(subAccountId);
        const successMessage = `${i18next.t('leverage_calculation_completed')} - ${subAccountName}`;

        const detailInfo: LeveregesCalculatorDetail = {
          detail: successMessage,
          type: 'success',
        };

        dispatch(setCalculatorDetailMessage(detailInfo));
      }
      dispatch(removeCalculationFromQueue(subAccountId));
    }

    if (eventType === 'FOR_SELECTED_INSTRUMENTS:FINISHED') {
      const { details, sub_account_id: subAccountId } = message.metadata.data;
      devConsoleLog('FINISHED CALCULATION SubAccount: ', subAccountId);

      if (details) {
        const subAccountName = getSubAccountName(subAccountId);
        const instrumentsQuantity = details.success_leverages + details.error_leverages;
        const successfulQuantity = instrumentsQuantity - details.error_leverages;

        const successMessage = `${i18next.t('leverage_calculation_completed')} - ${subAccountName}. ${i18next.t('success')} ${successfulQuantity} ${i18next.t('from_is')} ${instrumentsQuantity}`;

        const detailInfo: LeveregesCalculatorDetail = {
          detail: successMessage,
          type: 'success',
        };

        dispatch(setCalculatorDetailMessage(detailInfo));
      }
      dispatch(removeCalculationFromQueue(subAccountId));
    }
  }

  if (balanceEvent) {
    if (eventType === 'UPDATE') {
      const receivedBalance = message.metadata.data;

      dispatch(updateBalance(receivedBalance));
    }
  }

  if (totalBalanceEvent) {
    if (eventType === 'UPDATE') {
      const totalBalance = message.metadata.data;

      dispatch(updateTotalBalance(totalBalance));
    }
  }

  if (subAccountAssetEvent) {
    if (eventType === 'CREATE') {
      const subAccountAsset = message.metadata.data;

      const subAccountAssetFormatted = formatSubAccountAssetProcess(subAccountAsset);
      dispatch(addSubAccountAsset(subAccountAssetFormatted));
    }

    if (eventType === 'UPDATE') {
      const subAccountAsset = message.metadata.data;
      dispatch(updateSubAccountAsset(subAccountAsset));
    }

    if (eventType === 'REMOVE') {
      const subAccountAsset: SubAccountAssetRemove = message.metadata.data;
      dispatch(removeSubAccountAsset(subAccountAsset));
    }
  }

  if (instumentEvent) {
    if (eventType === 'UPDATE') {
      const instrument = message.metadata.data;
      const instrumentFormatted = formatInstrumentProcess(instrument);

      batch(() => {
        dispatch(updatePositionsInstrument(instrumentFormatted));
        dispatch(updateOrdersInstrument(instrumentFormatted));
        dispatch(updateSubAccountAssetInstrument(instrumentFormatted));
        dispatch(updatePositionsSummuryInstrument(instrumentFormatted));
      });
    }
  }

  if (transfersEvent) {
    if (eventType === 'CREATE') {
      const transfer = message.metadata.data;

      dispatch(addTransferHistory(transfer));
    }
  }

  if (notificationsEvent) {
    if (eventType === 'READ') {
      const notificationIds = message.metadata.data;
      dispatch(markReadByIds(notificationIds));
    }

    if (eventType === 'CREATE') {
      const newNotification = message.metadata.data;
      dispatch(addNotification(newNotification));
    }

    if (eventType === 'DELETE') {
      const notificationIds = message.metadata.data;
      dispatch(deleteNotification(notificationIds));
    }
  }
}

function reconnect() {
  const { dispatch } = store;

  clearInterval(pingInterval);
  clearInterval(heartBeatInterval);

  try {
    socket?.close();
    connectWebSocket()(dispatch);
  } catch (error) {
    devConsoleLog('Error when restoring WebSocket connection:', error);
  }
}

function pulse() {
  clearInterval(heartBeatInterval);
  heartBeatTimeoutSecond = 5;

  heartBeatInterval = setInterval(() => {
    devConsoleLog('heart beat - ', heartBeatTimeoutSecond);

    heartBeatTimeoutStart = true;

    if (heartBeatTimeoutSecond <= 0) {
      clearInterval(heartBeatInterval);
      reconnect();
    }

    heartBeatTimeoutSecond -= 1;
  }, HEARTBEAT_INTERVAL);
}

function ping() {
  if (socket && socket.readyState === WebSocket.OPEN && !heartBeatTimeoutStart) {
    // devConsoleLog('PING');
    heartBeatTimeoutStart = false;

    const message = {
      event: EInputEventType.PING,
    };

    socket.send(JSON.stringify(message));
    pulse();
  }
}

function subscribe(subscribeMessages: ISubscribeEvent[]) {
  if (socket && socket.readyState === WebSocket.OPEN) {
    subscribeMessages.forEach((subscribeMessage) => {
      if (socket) {
        devConsoleLog(`Subscription to ${subscribeMessage.metadata.event_type} restored`);
        socket.send(JSON.stringify(subscribeMessage));
      }
    });
  }
}

function subscriptionsRecovery() {
  const queueSubscriptions = store.getState().subscriptions.subscriptions;

  if (queueSubscriptions) subscribe(queueSubscriptions);
}
