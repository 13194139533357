import useMobile from 'src/shared/libs/hooks/use-mobile';
import LeveragesAdaptive from './adaptive/leverage-adaptive';
import Leverages from './leverages';

const LeveragesPage = () => {
  const isMobile = useMobile();

  return isMobile ? <LeveragesAdaptive /> : <Leverages />;
};

export default LeveragesPage;
